import { useCallback } from "react";
import { type CellContext } from "@tanstack/react-table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useSnackbar } from "notistack";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Locale } from "@/types";
import { showUpdateLocaleModal } from "@/components/translation/UpdateLocaleModal";
import { useGetLocales, useRemoveLocaleMutation } from "@/graphql/translation";
import { ConfirmationButton } from "../../common/ConfirmationButton";

export function LocalesPageActionsCell(props: CellContext<Locale, "guid">) {
    const gameId = props.row.original.gameId;
    const { enqueueSnackbar } = useSnackbar();
    const getLocales = useGetLocales({ gameId });
    const [removeLocaleMutation] = useRemoveLocaleMutation();
    const onEditLocale = useCallback(async () => {
        await showUpdateLocaleModal({ locale: props.row.original });
        return getLocales.refetch();
    }, [getLocales, props.row.original]);
    const onRemoveLocale = useCallback(async () => {
        await removeLocaleMutation({ variables: props.row.original });
        enqueueSnackbar("Locale removed", {
            variant: "success",
        });
        return getLocales.refetch();
    }, [removeLocaleMutation, props.row.original, enqueueSnackbar, getLocales]);

    return (
        <ButtonGroup size="sm">
            <Button onClick={onEditLocale} title="Edit">
                <FaEdit />
            </Button>
            <ConfirmationButton onConfirm={onRemoveLocale} variant="danger" title="Remove">
                <FaTrash />
            </ConfirmationButton>
        </ButtonGroup>
    );
}
