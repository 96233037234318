import { useCallback } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit, FaTrash } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { CellContext } from "@tanstack/react-table";
import { DeployDirectory } from "@/gql";
import {
    useDeleteDeployDirectoryMutation,
    useUpdateDeployDirectoryMutation,
} from "@/graphql/deploy-directories";
import { useDeployDirectoryModal } from "../modals/DeployDirectoryModal";
import { ConfirmationButton } from "../common/ConfirmationButton";

type Props = CellContext<DeployDirectory, unknown> & {
    onRefetch: () => void;
};

export function ActionsCell(props: Props) {
    const { onRefetch } = props;
    const row = props.row.original;
    const { gameId, guid } = row;

    const showDeployDirectoryModal = useDeployDirectoryModal();
    const [updateDeployDirectoryMutation] = useUpdateDeployDirectoryMutation();
    const [deleteDeployDirectoryMutation] = useDeleteDeployDirectoryMutation();

    const onEdit = useCallback(
        async (row: DeployDirectory) => {
            const result = await showDeployDirectoryModal({
                deployDirectory: row,
            });
            await updateDeployDirectoryMutation({
                variables: {
                    gameId,
                    guid: result.guid,
                    name: result.name,
                    variant: result.variant,
                    description: result.description,
                    path: result.path,
                },
            });
            return onRefetch();
        },
        [gameId, onRefetch, showDeployDirectoryModal, updateDeployDirectoryMutation],
    );

    const onDelete = useCallback(
        () =>
            deleteDeployDirectoryMutation({
                variables: { guid, gameId },
                onCompleted: onRefetch,
            }),
        [deleteDeployDirectoryMutation, gameId, guid, onRefetch],
    );
    const onEditDD = useCallback(() => onEdit(row), [row]);

    return row.path === "dev" ? (
        <span>No actions allowed</span>
    ) : (
        <ButtonGroup>
            <ConfirmationButton
                onConfirm={onDelete}
                size="sm"
                prompt="Are you sure you want to delete this deploy directory?"
                title="Delete deploy directory"
                variant="danger"
            >
                <FaTrash />
            </ConfirmationButton>
            <Button title="Edit deploy directory" size="sm" onClick={onEditDD}>
                <FaEdit />
            </Button>
        </ButtonGroup>
    );
}
