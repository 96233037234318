import { useCallback } from "react";
import { showEditEntityModal } from "@/components/modals/EditEntityModal";

type Props = {
    gameId: string;
    templateId: string;
    onChange: (value: string) => void;
};

export function useCreateComponent(props: Props) {
    return useCallback(async () => {
        const result = await showEditEntityModal(props);

        props.onChange(result.guid);
    }, [props]);
}
