import { useMemo } from "react";
import sortBy from "lodash/sortBy";
import { useCategoriesSidebarContext } from "@/components/layout/CategoriesSidebarContext";
import { TemplateCategory } from "@/gql";
import { TemplatesCategory } from "@/components/layout/TemplatesCategory";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

type TC = Omit<TemplateCategory, "game">;

type Props = {
    templateCategories: TC[];
    onItemSelected: () => void;
};

export function FavoriteTemplatesCategory(props: Props) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const { getIsFavorite } = useCategoriesSidebarContext();
    const templateCategory = useMemo<TC>(
        () => ({
            gameId,
            name: "Favorites",
            guid: "",
            templates: sortBy(
                props.templateCategories
                    .map((tc) => tc.templates.filter((t) => getIsFavorite(t.guid)))
                    .flat(),
                "name",
            ),
        }),
        [props.templateCategories, getIsFavorite],
    );

    return (
        <TemplatesCategory
            className="mb-4"
            key="favorites"
            data={templateCategory}
            onItemSelected={props.onItemSelected}
        />
    );
}
