import { useMemo } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { createColumnHelper } from "@tanstack/react-table";
import { DataType, TemplateMatchModel, TemplateParamMatchModel } from "@/gql";
import { TemplateParamExpanded } from "@/components/import-page/TemplateParamExpanded";
import { DataTable2 } from "@/components/common/DataTable2";
import { useImportPageContext } from "@/components/import-page/ImportPageProvider";

type Props = {
    data: TemplateMatchModel;
};

const columnHelper = createColumnHelper<TemplateParamMatchModel>();

export function ImportTemplateExpandedRow(props: Props) {
    const { data } = props;
    const { templatesMap, isTemplateSelected, canSelect, onSelect } = useImportPageContext();

    const columns = useMemo(
        () => [
            columnHelper.accessor("sourceTemplateParam.name", { header: "Template parameter" }),
            columnHelper.accessor("sourceTemplateParam.guid", { header: "Template id" }),
            columnHelper.accessor("targetTemplateParam.guid", {
                header: "Match id",
                cell: (props) => {
                    const targetTemplateParam = props.row.original.targetTemplateParam;

                    return targetTemplateParam ? targetTemplateParam.guid : "No match";
                },
            }),
            columnHelper.accessor("sourceTemplateParam.type", {
                header: "Parameter type",
                cell: (props) => {
                    const type = props.getValue();
                    const row = props.row.original;
                    return type === DataType.List
                        ? `${type}<${row.sourceTemplateParam.subType}>`
                        : type;
                },
            }),
            columnHelper.display({
                header: "Referenced template",
                cell: (props) => {
                    const row = props.row.original;
                    if (!row.sourceTemplateParam.meta.templateId) return "";
                    if (!templatesMap?.[row.sourceTemplateParam.meta.templateId]) return "";
                    const sourceMatch =
                        templatesMap[row.sourceTemplateParam.meta.templateId]?.sourceMatch;

                    if (!sourceMatch) return "";

                    const templateName = sourceMatch.name;
                    const templateType = sourceMatch.type;

                    return (
                        <>
                            <div>{templateName}</div>
                            <small>{templateType}</small>
                        </>
                    );
                },
            }),
            columnHelper.display({
                header: "Actions",
                cell: (props) => {
                    const row = props.row.original;
                    if (!row.sourceTemplateParam.meta.templateId) return null;

                    const templateSelected = isTemplateSelected!(
                        row.sourceTemplateParam.meta.templateId,
                    );
                    const cannotSelect = !canSelect(row.sourceTemplateParam.meta.templateId);

                    return (
                        <>
                            {typeof onSelect === "function" && (
                                <Button
                                    size="sm"
                                    variant={templateSelected ? "outline-primary" : "primary"}
                                    disabled={cannotSelect}
                                    onClick={() =>
                                        onSelect!(row.sourceTemplateParam.meta.templateId!)
                                    }
                                >
                                    {cannotSelect
                                        ? "Cannot import"
                                        : templateSelected
                                          ? "Selected for import"
                                          : "Select for import"}
                                </Button>
                            )}
                        </>
                    );
                },
            }),
        ],
        [canSelect, isTemplateSelected, onSelect, templatesMap],
    );

    return (
        <Container fluid className="ps-5 pe-2 py-5 bg-body-secondary">
            {data.matchErrors.length > 0 && (
                <>
                    <h5>Why</h5>
                    <ul>
                        {data.matchErrors.map((error, index) => (
                            <li key={index} className="text-danger">
                                <p>{error}</p>
                            </li>
                        ))}
                    </ul>
                </>
            )}
            <h5>Template parameters</h5>
            <DataTable2
                columns={columns}
                data={data.templateParamsMatches}
                getRowCanExpand={(row) => row.original.matchErrors.length === 0}
                expandableRowsComponent={TemplateParamExpanded}
                bsTableProps={{ striped: true }}
            />
        </Container>
    );
}
