import Form from "react-bootstrap/Form";
import { CellContext } from "@tanstack/react-table";
import { ChangeEventHandler, useCallback } from "react";
import { useSetPermissionsDisabledStateAction } from "@/store/games/actions";
import { UserPermission } from "../types";

type Props = CellContext<UserPermission, unknown> & {
    gameId: string;
    currentUserId: string;
    isDisabled?: boolean;
};

export function IsDisabledCell(props: Props) {
    const { currentUserId, isDisabled, gameId } = props;
    const userId = props.row.original.userId;

    const [setPermissionsDisabledStateAction, { loading }] = useSetPermissionsDisabledStateAction();
    const onDisabledChange = useCallback(
        async (userId: string, newDisabledState: boolean) => {
            await setPermissionsDisabledStateAction({
                userId,
                gameId,
                newDisabledState,
            });
        },
        [gameId, setPermissionsDisabledStateAction],
    );
    const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => onDisabledChange(userId, event.target.checked),
        [onDisabledChange, userId],
    );

    return currentUserId !== props.getValue() ? (
        <Form.Switch disabled={loading} defaultChecked={isDisabled} onChange={onChange} />
    ) : null;
}
