import { useCallback } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import Stack from "react-bootstrap/Stack";
import { RBACWrapper, withRBAC } from "@/components/rbac";
import { GlobalParamsTable } from "@/components/common/Table/GlobalParamsTable";
import { DataType, Permission } from "@/gql";
import { useGlobalParameters, useRemoveTemplateParamMutation } from "@/graphql/template-params";
import { showGlobalParamModal } from "@/components/modals/GlobalParamModal";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

const GlobalParams = withRBAC(
    function GlobalParams() {
        const { useParams } = globalParamsRoute;
        const { gameId } = useParams();
        const globalParameters = useGlobalParameters({ gameId: gameId! });
        const onCompleted = useCallback(() => globalParameters.refetch(), [globalParameters]);
        const [removeTemplateParam, { loading: removeTemplateParamLoading }] =
            useRemoveTemplateParamMutation({
                onCompleted,
            });
        const loading = removeTemplateParamLoading || globalParameters.loading;

        const onGlobalParamCreate = useCallback(
            async (dataType: DataType) => {
                if (!gameId) throw new Error("gameId parameter is missing");

                await showGlobalParamModal({
                    gameId,
                    type: dataType,
                });

                await globalParameters.refetch();
            },
            [gameId, globalParameters],
        );
        const onGlobalParamEdit = useCallback(
            async (guid: string) => {
                const globalParam = globalParameters.data?.globalParameters.find(
                    (parameter) => parameter.guid === guid && parameter.gameId === gameId,
                );

                if (!gameId) throw new Error("gameId parameter is missing");
                if (!globalParam) throw new Error("No global parameter to edit");

                await showGlobalParamModal({
                    gameId,
                    type: globalParam.type,
                    param: globalParam,
                });

                await globalParameters.refetch();
            },
            [gameId, globalParameters],
        );
        const onGlobalParamRemove = useCallback(
            async (guid: string) => {
                await removeTemplateParam({ variables: { guid, gameId: gameId! } });
            },
            [gameId, removeTemplateParam],
        );

        return (
            <Stack gap={2}>
                <Helmet title="Global parameters" />
                <RBACWrapper
                    requiredPermissions={[Permission.DataWrite, Permission.StructureWrite]}
                >
                    <Dropdown>
                        <Dropdown.Toggle variant="success">Create new</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                disabled={loading}
                                onClick={() => onGlobalParamCreate(DataType.Enum)}
                            >
                                Enum
                            </Dropdown.Item>
                            <Dropdown.Item
                                disabled={loading}
                                onClick={() => onGlobalParamCreate(DataType.Script)}
                            >
                                Script scope
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </RBACWrapper>
                <DataAwaiter2 {...globalParameters}>
                    {(data) => (
                        <GlobalParamsTable
                            loading={loading}
                            onRemove={onGlobalParamRemove}
                            onEdit={onGlobalParamEdit}
                            globalParameters={data.globalParameters}
                        />
                    )}
                </DataAwaiter2>
            </Stack>
        );
    },
    {
        requiredPermissions: [
            Permission.StructureRead,
            Permission.StructureWrite,
            Permission.DataWrite,
            Permission.DataRead,
        ],
        oneOf: true,
    },
);

export default GlobalParams;

export const globalParamsRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "global-params",
    component: GlobalParams,
});
