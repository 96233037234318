import { ChangeEvent, useCallback, useMemo } from "react";
import cn from "classnames";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { FaTrash } from "react-icons/fa";
import { useSnackbar } from "notistack";
import { useDroppable } from "@dnd-kit/core";
import { useDebounceCallback } from "usehooks-ts";
import {
    useDeleteTemplateCategory,
    useTemplateCategories,
    useUpdateTemplateCategory,
} from "@/graphql/templates";
import { TemplateCategoriesQuery } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { CategoryTemplate } from "@/components/categories-page/CategoryTemplate";

type Props = {
    className?: string;
    data: TemplateCategoriesQuery["templateCategories"][number];
};

export function Category(props: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const isDefaultCategory = props.data.name === "Unassigned";
    const templateCategories = useTemplateCategories({ gameId: props.data.gameId });
    const [updateTemplateCategory] = useUpdateTemplateCategory();
    const [deleteTemplateCategory] = useDeleteTemplateCategory();
    const { isOver, setNodeRef } = useDroppable({
        id: props.data.guid,
        data: props.data,
    });
    const onDeleteCategory = useCallback(async () => {
        await deleteTemplateCategory({
            variables: { guid: props.data.guid, gameId: props.data.gameId },
        });
        await templateCategories.refetch();
        enqueueSnackbar(`Category ${props.data.name} deleted`, {
            variant: "success",
        });
    }, [
        deleteTemplateCategory,
        props.data.gameId,
        props.data.guid,
        props.data.name,
        enqueueSnackbar,
        templateCategories,
    ]);

    const renameCategory = useCallback(
        async (newName: string) => {
            await updateTemplateCategory({
                variables: {
                    gameId: props.data.gameId,
                    guid: props.data.guid,
                    name: newName,
                },
            });
            await templateCategories.refetch();
            enqueueSnackbar("Category renamed", {
                variant: "success",
            });
        },
        [
            props.data.gameId,
            props.data.guid,
            enqueueSnackbar,
            templateCategories,
            updateTemplateCategory,
        ],
    );
    const className = useMemo(() => {
        return cn("position-relative", props.className);
    }, [props.className]);
    const categoryClassName = useMemo(
        () =>
            cn("border border-2 rounded position-relative", className, {
                "border-primary": isOver,
                "z-1": isOver,
                "border-white": !isOver,
            }),
        [isOver, className],
    );
    const onNameChange = useDebounceCallback((event: ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        return renameCategory(name);
    }, 300);

    return (
        <Card
            className={categoryClassName}
            style={{ borderStyle: isOver ? "dashed !important" : "unset" }}
        >
            <Card.Header className="d-flex justify-content-between align-items-center">
                <div className="flex-grow-1">
                    <Form.Control
                        plaintext
                        defaultValue={props.data.name}
                        onChange={onNameChange}
                        disabled={isDefaultCategory}
                    />
                </div>
                <ConfirmationButton
                    size="sm"
                    variant="link"
                    prompt="Are you sure?"
                    onConfirm={onDeleteCategory}
                    disabled={isDefaultCategory}
                    title="Delete category"
                >
                    <FaTrash size={24} />
                </ConfirmationButton>
            </Card.Header>
            <Card.Body>
                <div
                    className="d-flex flex-column gap-2 h-100"
                    style={{ minHeight: 100 }}
                    ref={setNodeRef}
                >
                    {props.data.templates.map((template) => (
                        <CategoryTemplate key={template.guid} template={template} />
                    ))}
                </div>
            </Card.Body>
        </Card>
    );
}
