import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from "react";
import keyBy from "lodash/keyBy";
import orderBy from "lodash/orderBy";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Badge from "react-bootstrap/Badge";
import { FaFile, FaFileExport, FaFileImport } from "react-icons/fa";
import { MdTranslate } from "react-icons/md";
import { useDebounceCallback } from "usehooks-ts";
import { createColumnHelper } from "@tanstack/react-table";
import { Locale, Translation as GqlTranslation, TranslationsQuery, TranslationString } from "@/gql";
import { showExportTranslationModal } from "@/components/modals/ExportTranslationsModal";
import { showImportTranslationsModal } from "@/components/modals/ImportTranslationsModal";
import { DataTable2 } from "@/components/common/DataTable2";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { TranslationPageLayoutNav } from "@/pages/translation/TranslationPageLayout";
import { showCreateDeeplTranslationModal } from "./modals/CreateDeeplTranslationModal";
import { showCreateTranslationModal } from "./modals/CreateTranslationModal";
import { useTranslationsPageContext } from "./TranslationsPageProvider";
import { useBulkUpdateFromDeepl } from "./modals/BulkUpdateFromDeepl";
import { ActionsColumnCell } from "./components/ActionsColumnCell";
import { TranslationKeyCell } from "./components/TranslationKeyCell";
import { TranslationDescriptionCell } from "./components/TranslationDescriptionCell";
import { TablePagination } from "./components/TablePagination";

type Props = {
    gameId: string;
    data: TranslationsQuery;
    total: number;
};

type Translation = Pick<GqlTranslation, "description" | "gameId" | "guid" | "key">;
type TranslationData = Translation & { values: Record<Locale["code"], TranslationString["value"]> };

const TranslationCell = styled.div`
    min-width: 15vw;
    max-width: 20vw;
    white-space: break-spaces;
`;

const columnHelper = createColumnHelper<TranslationData>();

export function TranslationsTable(props: Props) {
    const { translations, pagination } = useTranslationsPageContext();
    const localesMap = useMemo(() => keyBy(props.data.locales, "guid"), [props.data.locales]);
    const filterInputRef = useRef<HTMLInputElement | null>(null);
    const bulkUpdateFromDeepl = useBulkUpdateFromDeepl();
    const onQueryChange = useDebounceCallback((e: ChangeEvent<HTMLInputElement>) => {
        const newQuery = e.target.value;

        if (pagination.query !== newQuery) {
            pagination.setQuery(newQuery);
        }
    }, 1000);
    const onCreateDeeplTranslation = useCallback(async () => {
        await showCreateDeeplTranslationModal({
            gameId: props.gameId,
            locales: props.data.locales,
        });
        return translations.refetch();
    }, [props.data.locales, props.gameId, translations]);
    const onBulkUpdateFromDeepl = useCallback(async () => {
        await bulkUpdateFromDeepl({
            gameId: props.gameId,
        });
        return translations.refetch();
    }, [bulkUpdateFromDeepl, props.gameId, translations]);
    const onCreateTranslation = useCallback(async () => {
        await showCreateTranslationModal({
            gameId: props.gameId,
            locales: props.data.locales,
        });
        return translations.refetch();
    }, [props.data.locales, props.gameId, translations]);
    const onExport = useCallback(() => {
        return showExportTranslationModal({
            gameId: props.gameId,
            locales: props.data.locales,
        });
    }, [props.data.locales, props.gameId]);
    const onImport = useCallback(async () => {
        await showImportTranslationsModal({ gameId: props.gameId });
        await translations.refetch();
    }, [props.gameId, translations]);
    const data = useMemo<TranslationData[]>(() => {
        return props.data.translations.map((translation) => {
            return {
                guid: translation.guid,
                gameId: translation.gameId,
                key: translation.key,
                description: translation.description,
                values: translation.translationStrings.reduce(
                    (acc, value) => {
                        const locKey = localesMap[value.localeId]?.code;

                        if (locKey !== undefined) {
                            acc[locKey] = value.value;
                        }

                        return acc;
                    },
                    {} as Record<Locale["code"], TranslationString["value"]>,
                ),
            };
        });
    }, [localesMap, props.data.translations]);
    const columns = useMemo(() => {
        return [
            columnHelper.accessor("guid", {
                header: "Actions",
                cell: ActionsColumnCell,
                size: 100,
            }),
            columnHelper.accessor("key", {
                header: "Key",
                cell: TranslationKeyCell,
                size: 200,
            }),
            columnHelper.accessor("description", {
                header: "Description",
                cell: TranslationDescriptionCell,
                minSize: 350,
            }),
            ...orderBy(props.data.locales, (v) => !v.isBaseLocale).map((locale) => {
                return columnHelper.accessor(`values.${locale.guid}`, {
                    header: () => (
                        <div className="d-flex gap-1">
                            {locale.name} ({locale.code})
                            {locale.isBaseLocale && <Badge>Base locale</Badge>}
                        </div>
                    ),
                    cell: (props) => {
                        const row = props.row.original;

                        return (
                            <TranslationCell>
                                {row.values[locale.code] ?? <Badge bg="info">No translation</Badge>}
                            </TranslationCell>
                        );
                    },
                    size: 300,
                });
            }),
        ];
    }, [props.data.locales]);

    useEffect(() => {
        filterInputRef.current?.focus();
    }, []);

    return (
        <>
            <div className="d-flex justify-content-between">
                <TranslationPageLayoutNav />
                <ButtonGroup>
                    <Button onClick={onExport}>
                        <FaFileImport /> Export to spreadsheet
                    </Button>
                    <Button onClick={onImport}>
                        <FaFileExport /> Import from spreadsheet
                    </Button>
                    <Button onClick={onCreateTranslation}>
                        <FaFile /> Create
                    </Button>
                    <Button onClick={onBulkUpdateFromDeepl}>
                        <MdTranslate /> Bulk update from Deepl
                    </Button>
                    <Button onClick={onCreateDeeplTranslation}>
                        <MdTranslate /> Create from Deepl
                    </Button>
                </ButtonGroup>
            </div>
            <Form.Control
                ref={filterInputRef}
                defaultValue={pagination.query}
                placeholder="Filter"
                onChange={onQueryChange}
            />
            <DataAwaiter2 {...translations}>
                {(translationsData) => (
                    <>
                        <DataTable2 data={data} columns={columns} />
                        <TablePagination
                            {...pagination}
                            total={translationsData.translationsTotal}
                        />
                    </>
                )}
            </DataAwaiter2>
        </>
    );
}
