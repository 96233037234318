import { CellContext } from "@tanstack/react-table";
import Button from "react-bootstrap/Button";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";

export function ExpandRowCell<Data extends Record<string, any>>(props: CellContext<Data, unknown>) {
    const { row } = props;

    return (
        <Button
            variant="link"
            onClick={row.getToggleExpandedHandler()}
            disabled={!row.getCanExpand()}
        >
            {row.getIsExpanded() ? <FaChevronDown /> : <FaChevronRight />}
        </Button>
    );
}
