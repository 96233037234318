import { createContext, useContext } from "react";
import { TemplateMatchModel } from "@/gql";

export type ImportPageContext = {
    templatesMap: Record<string, TemplateMatchModel>;
    onSelect: (guid: string) => void;
    isTemplateSelected: (guid: string) => boolean;
    canSelect: (guid: string) => boolean;
};

export const ImportPageContext = createContext<ImportPageContext>(undefined!);

export function useImportPageContext() {
    return useContext(ImportPageContext);
}
