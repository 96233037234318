import { useCallback, useMemo } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { createColumnHelper } from "@tanstack/react-table";
import {
    useCreateDeployDirectoryMutation,
    useDeployDirectoriesQuery,
} from "@/graphql/deploy-directories";
import { DeployDirectory, GetDeployDirectoriesQuery } from "@/gql";
import { useDeployDirectoryModal } from "@/components/modals/DeployDirectoryModal";
import { DataTable2 } from "@/components/common/DataTable2";
import { ActionsCell } from "@/components/deploy-directories/ActionsCell";

const columnHelper = createColumnHelper<DeployDirectory>();

export function DeployDirectoriesTable(props: GetDeployDirectoriesQuery & { gameId: string }) {
    const deployDirectories = useDeployDirectoriesQuery({ gameId: props.gameId });
    const showDeployDirectoryModal = useDeployDirectoryModal();
    const [createDeployDirectoryMutation] = useCreateDeployDirectoryMutation();
    const onRefetch = useCallback(() => deployDirectories.refetch(), [deployDirectories]);

    const onCreate = useCallback(async () => {
        const result = await showDeployDirectoryModal({});
        await createDeployDirectoryMutation({
            variables: {
                gameId: props.gameId,
                name: result.name,
                path: result.path,
                variant: result.variant,
            },
        });
        await deployDirectories.refetch();
    }, [createDeployDirectoryMutation, deployDirectories, props.gameId, showDeployDirectoryModal]);

    const columns = useMemo(() => {
        return [
            columnHelper.accessor("name", { header: "Name" }),
            columnHelper.accessor("description", { header: "Description" }),
            columnHelper.accessor("path", { header: "Path" }),
            columnHelper.accessor("variant", { header: "Variant" }),
            columnHelper.display({
                header: "Actions",
                cell: (props) => {
                    return <ActionsCell {...props} onRefetch={onRefetch} />;
                },
            }),
        ];
    }, [onRefetch]);

    return (
        <>
            <div>
                <ButtonGroup>
                    <Button onClick={onCreate}>Create</Button>
                </ButtonGroup>
            </div>
            <DataTable2
                data={props.deployDirectories}
                columns={columns}
                bsTableProps={{ striped: true }}
            />
        </>
    );
}
