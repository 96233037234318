import { useCallback, useMemo } from "react";
import keyBy from "lodash/keyBy";
import { mapValue } from "graphql/jsutils/mapValue";
import orderBy from "lodash/orderBy";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FaFile, FaFileExport, FaFileImport } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";
import { createColumnHelper } from "@tanstack/react-table";
import { GetGlossariesPageQuery } from "@/gql";
import { showEditGlossaryEntryModal } from "@/components/translation/translations/modals/EditGlossaryEntryModal";
import { showExportGlossariesModal } from "@/components/modals/ExportGlossariesModal";
import { showImportGlossariesModal } from "@/components/modals/ImportGlossariesModal";
import { useCreateGlossaryEntryFromDeepl } from "@/components/translation/translations/modals/CreateGlossaryEntryFromDeeplModal";
import { GlossariesPageActionColumn } from "@/components/translation/glossaries/GlossariesPageActionColumn";
import { DataTable2 } from "@/components/common/DataTable2";
import { useGetGlossaries } from "@/components/translation/glossaries/hooks/useGlossaries";
import { TranslationPageLayoutNav } from "@/pages/translation/TranslationPageLayout";
import { GlossaryEntryTransformed } from "../types";

type Props = {
    gameId: string;
    data: GetGlossariesPageQuery;
};

const columnHelper = createColumnHelper<GlossaryEntryTransformed>();

export function GlossariesTable(props: Props) {
    const getGlossaries = useGetGlossaries(props.gameId);
    const data = useMemo(() => {
        return props.data.getGlossaries.map<GlossaryEntryTransformed>((glossary) => ({
            ...glossary,
            values: mapValue(keyBy(glossary.values, "localeId"), (v) => v.value),
        }));
    }, [props.data.getGlossaries]);
    const showCreateGlossaryEntryFromDeeplModal = useCreateGlossaryEntryFromDeepl();
    const onCreateGlossaryEntry = useCallback(async () => {
        await showEditGlossaryEntryModal({ gameId: props.gameId });
        await getGlossaries.refetch();
    }, [getGlossaries, props.gameId]);
    const onCreateGlossaryEntryFromDeepl = useCallback(async () => {
        await showCreateGlossaryEntryFromDeeplModal({
            gameId: props.gameId,
        });
        await getGlossaries.refetch();
    }, [getGlossaries, props.gameId, showCreateGlossaryEntryFromDeeplModal]);
    const onExport = useCallback(() => {
        return showExportGlossariesModal({
            gameId: props.gameId,
            locales: props.data.locales,
        });
    }, [props.data.locales, props.gameId]);
    const onImport = useCallback(async () => {
        await showImportGlossariesModal({ gameId: props.gameId });
        await getGlossaries.refetch();
    }, [getGlossaries, props.gameId]);

    const columns = useMemo(
        () => [
            columnHelper.accessor("guid", {
                header: "Actions",
                cell: GlossariesPageActionColumn,
            }),
            ...orderBy(
                props.data.locales.map((locale) =>
                    columnHelper.accessor(`values.${locale.guid}`, {
                        header: () => (
                            <span>
                                {locale.name} ({locale.code}){" "}
                                {locale.isBaseLocale && <Badge>Base locale</Badge>}
                            </span>
                        ),
                        cell: (props) => {
                            const row = props.row.original;

                            return <span>{row.values[locale.guid]}</span>;
                        },
                    }),
                ),
                "isBaseLocale",
            ),
        ],
        [props.data.locales],
    );

    return (
        <>
            <div className="d-flex justify-content-between">
                <TranslationPageLayoutNav />
                <ButtonGroup size="sm">
                    <Button disabled={getGlossaries.loading} onClick={onExport}>
                        <FaFileImport /> Export to spreadsheet
                    </Button>
                    <Button disabled={getGlossaries.loading} onClick={onImport}>
                        <FaFileExport /> Import from spreadsheet
                    </Button>
                    <Button disabled={getGlossaries.loading} onClick={onCreateGlossaryEntry}>
                        <FaFile /> Create
                    </Button>
                    <Button
                        disabled={getGlossaries.loading}
                        onClick={onCreateGlossaryEntryFromDeepl}
                    >
                        <FaFile /> Create from Deepl
                    </Button>
                </ButtonGroup>
            </div>
            <DataTable2 data={data} columns={columns} />
        </>
    );
}
