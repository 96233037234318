import { useCallback } from "react";
import { useUpdateTranslationFromDeeplMutation } from "@/graphql/translation";
import { Translation } from "../../types";
import { useTranslationsPageContext } from "../TranslationsPageProvider";

export function useUpdateTranslationFromDeepl() {
    const [updateTranslationFromDeepl] = useUpdateTranslationFromDeeplMutation();
    const { translations } = useTranslationsPageContext();

    return useCallback(
        async (row: Translation) => {
            await updateTranslationFromDeepl({
                variables: {
                    guid: row.guid,
                    gameId: row.gameId,
                },
            });
            await translations.refetch();
        },
        [translations, updateTranslationFromDeepl],
    );
}
