import { createRoute } from "@tanstack/react-router";
import { useGetLocales } from "@/graphql/translation";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { LocalesTable } from "@/components/translation/locales/LocalesTable";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

export default function LocalesPage() {
    const { useParams } = localesPageRoute;
    const { gameId } = useParams();
    const getLocales = useGetLocales({ gameId });

    return (
        <DataAwaiter2 {...getLocales}>
            {(data) => <LocalesTable gameId={gameId} data={data} />}
        </DataAwaiter2>
    );
}

export const localesPageRoute = createRoute({
    // getParentRoute: () => translationPageLayoutRoute,
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "translation/locales",
    component: LocalesPage,
});
