import { gql, useQuery } from "@apollo/client";
import {
    GLOSSARY_ENTRY_FRAGMENT,
    GLOSSARY_ENTRY_VALUE_FRAGMENT,
} from "@/graphql/fragments/glossary.fragment";
import { LOCALE_FRAGMENT } from "@/graphql/fragments/locale.fragment";
import { GetGlossariesPageQuery, GetGlossariesPageQueryVariables } from "@/gql";

const SCHEMA = gql`
    ${GLOSSARY_ENTRY_FRAGMENT}
    ${GLOSSARY_ENTRY_VALUE_FRAGMENT}
    ${LOCALE_FRAGMENT}

    query getGlossariesPage($gameId: String!) {
        getGlossaries(gameId: $gameId) {
            ...GlossaryEntry
            values {
                ...GlossaryEntryValue
            }
        }
        locales(gameId: $gameId) {
            ...Locale
        }
    }
`;

export function useGetGlossaries(gameId: string) {
    return useQuery<GetGlossariesPageQuery, GetGlossariesPageQueryVariables>(SCHEMA, {
        variables: { gameId },
    });
}
