import { createRoute } from "@tanstack/react-router";
import { createColumnHelper } from "@tanstack/react-table";
import { withRBAC } from "@/components/rbac/withRBAC";
import { ArtifactHistoryEntry, Permission } from "@/gql";
import { useGetArtifactsHistory } from "@/graphql/deploy";
import { DataAwaiter } from "@/components/common/DataAwaiter";
import { DateDisplay } from "@/components/common/DateDisplay";
import { UserInfoAsync } from "@/components/common/UserInfoAsync";
import { artifactRoute } from "@/pages/Artifacts";
import { DataTable2 } from "@/components/common/DataTable2";

const columnHelper = createColumnHelper<ArtifactHistoryEntry>();

const columns = [
    columnHelper.accessor("createdAt", {
        header: "Date",
        size: 200,
        cell: (props) => <DateDisplay format="DD MMM YYYY HH:mm" date={props.getValue()} />,
    }),
    columnHelper.accessor("createdBy", {
        header: "Creator",
        size: 500,
        cell: (props) => <UserInfoAsync userId={props.getValue()} />,
    }),
    columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => props.getValue(),
    }),
    columnHelper.accessor("artifactId", {
        header: "Artifact id",
        size: 500,
        cell: (props) => {
            const artifactId = props.getValue();
            return artifactId === null ? "Deleted artifact" : `#${artifactId}`;
        },
    }),
];

function ArtifactsHistory() {
    const useParams = artifactsHistoryRoute.useParams;
    const { gameId } = useParams();
    const { data, loading, error } = useGetArtifactsHistory({
        gameId: gameId!,
    });

    return (
        <DataAwaiter data={data} isLoading={loading} error={error}>
            {(data) => (
                <DataTable2
                    columns={columns}
                    data={data.artifactHistoryEntries}
                    bsTableProps={{ striped: true }}
                />
            )}
        </DataAwaiter>
    );
}

const ArtifactsHistoryRBAC = withRBAC(ArtifactsHistory, {
    requiredPermissions: [Permission.CanDeploy, Permission.DataRead, Permission.StructureRead],
});
export default ArtifactsHistoryRBAC;
export const artifactsHistoryRoute = createRoute({
    getParentRoute: () => artifactRoute,
    path: "history",
    component: ArtifactsHistoryRBAC,
});
