export function resolveBucketPath({
    gameId,
    consumerId,
    deployDirectoryPath,
    entityName,
    entityVersion,
}: {
    gameId: string;
    consumerId: string;
    deployDirectoryPath: string;
    entityName: string;
    entityVersion: string;
}) {
    return `${window.env.bucketUrl}/${window.env.bucketPathPrefix}entities/${gameId}/${consumerId}/${deployDirectoryPath}/${entityName}_v${entityVersion}.json`;
}
