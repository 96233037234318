import { ChangeEventHandler, MouseEventHandler, ReactNode, useMemo, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import groupBy from "lodash/groupBy";
import { uniqBy } from "lodash";
import Stack from "react-bootstrap/Stack";
import Nav from "react-bootstrap/Nav";
import { useSessionStorage } from "usehooks-ts";
import { CasedButton } from "@/components/common/CasedButton";
import { DateDisplay } from "@/components/common/DateDisplay";
import { JobModel } from "@/gql";
import { stringify } from "@/utils/stringify";
import { DataTable2 } from "@/components/common/DataTable2";
import { useJobDetailsModal } from "./JobDetailsModal";

type Props = {
    jobs: JobModel[];
    actionsColumn?: (row: JobModel) => ReactNode[] | ReactNode;
};

const columnHelper = createColumnHelper<JobModel>();

export function JobsTable(props: Props) {
    const showJobDetailsModal = useJobDetailsModal();
    const jobTypes = useMemo(() => uniqBy(props.jobs, "name").map((v) => v.name), [props.jobs]);
    const [selectedJobType, setSelectedJobType] = useSessionStorage(
        "jobs-list-selected-job-type",
        jobTypes.at(0),
    );
    const displayedJobs = useMemo(
        () => props.jobs.filter((job) => job.name === selectedJobType),
        [props.jobs, selectedJobType],
    );
    const columns = useMemo(
        () => [
            columnHelper.accessor("name", {
                header: "Job name",
                size: 200,
                cell: (props) => {
                    const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
                        e.preventDefault();
                        showJobDetailsModal({ data: props.row.original });
                    };

                    return (
                        <a href="#" onClick={onClick}>
                            {props.getValue()}
                        </a>
                    );
                },
            }),
            columnHelper.accessor("status", { header: "Status", size: 100 }),
            columnHelper.accessor("returnValue", {
                header: "Return value",
                size: 300,
                cell: (props) =>
                    props.getValue() !== null ? stringify(props.getValue()) : "No return value",
            }),
            columnHelper.accessor("progress", {
                header: "Progress",
                cell: (props) => props.getValue().toFixed(4),
            }),
            columnHelper.accessor("timestamp", {
                header: "Timestamp",
                size: 200,
                cell: (row) => <DateDisplay date={row.getValue()} format="DD MMM YYYY HH:mm:ss" />,
            }),
            columnHelper.accessor("processedOn", {
                header: "Processed on",
                size: 200,
                cell: (props) => {
                    const processedOn = props.getValue();

                    return processedOn ? (
                        <DateDisplay date={processedOn} format="DD MMM YYYY HH:mm:ss" />
                    ) : (
                        <span>Never</span>
                    );
                },
            }),
            columnHelper.accessor("finishedOn", {
                header: "Finished on",
                size: 200,
                cell: (props) => {
                    const finishedOn = props.getValue();

                    return finishedOn ? (
                        <DateDisplay date={finishedOn} format="DD MMM YYYY HH:mm:ss" />
                    ) : (
                        <span>Never</span>
                    );
                },
            }),
        ],
        [showJobDetailsModal],
    );

    return (
        <Stack>
            <Nav
                variant="tabs"
                activeKey={selectedJobType}
                onSelect={(eventKey) => eventKey && setSelectedJobType(eventKey)}
            >
                {jobTypes.map((jobType) => (
                    <Nav.Item key={jobType}>
                        <Nav.Link eventKey={jobType}>{jobType}</Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
            <DataTable2 data={displayedJobs} columns={columns} />
        </Stack>
    );
}
