import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link as NavLink, useRouter, RouteIds, AnyRouteMatch } from "@tanstack/react-router";
import { type FC, useMemo } from "react";
import { RegisteredRouter } from "@tanstack/react-router/dist/esm/router";
import { MakeRouteMatch } from "@tanstack/react-router/dist/esm/Matches";
import capitalize from "lodash/capitalize";
import { useAppSelector } from "@/hooks";
import { gameDetailsSelector, templatesMapSelector } from "@/store/games/selectors";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { templatePageRoute } from "@/pages/template/Template";
import { translationPageLayoutRoute } from "@/pages/translation/TranslationPageLayout";
import { entityPageRoute } from "@/pages/EntityPage";

function TemplateBreadcrumb({ match }: BreadcrumbComponentProps) {
    const templatesMap = useAppSelector(templatesMapSelector);
    const template = templatesMap[match.params.templateId];

    return <span>{template?.name ?? "Unknown template"}</span>;
}

function GameBreadcrumb() {
    const { name: gameName } = useAppSelector(gameDetailsSelector);
    return <span>{gameName}</span>;
}

function EntityBreadcrumb({ match }: BreadcrumbComponentProps) {
    const { entityId } = match.params;
    return <span>Entity: {entityId}</span>;
}

type BreadcrumbComponentProps = { match: AnyRouteMatch };
type BreadcrumbComponent = FC<BreadcrumbComponentProps>;
type BreadcrumbElement = string | BreadcrumbComponent;

const breadcrumbRoutes: () => Partial<
    Record<RouteIds<RegisteredRouter["routeTree"]>, BreadcrumbElement>
> = () => ({
    [layoutWithBreadcrumbs.id]: GameBreadcrumb,
    [templatePageRoute.id]: TemplateBreadcrumb,
    [translationPageLayoutRoute.id]: "Translations",
    [entityPageRoute.id]: EntityBreadcrumb,
});

function renderFallbackBreadcrumb(pathname: string) {
    const split = pathname.split("/").pop() ?? "";
    return capitalize(split.replace("-", " "));
}

function getBreadcrumbElement(match: MakeRouteMatch) {
    const BreadcrumbComponent = breadcrumbRoutes()[match.routeId];
    switch (typeof BreadcrumbComponent) {
        case "function":
            return <BreadcrumbComponent match={match} />;
        case "string":
            return capitalize(BreadcrumbComponent);
        default: {
            return renderFallbackBreadcrumb(match.pathname);
        }
    }
}

function useBreadcrumbs() {
    const router = useRouter();
    return useMemo(() => {
        return router.state.matches.map((match) => {
            return {
                to: match.pathname,
                key: match.id,
                breadcrumb: getBreadcrumbElement(match),
            };
        });
    }, [router.state.matches]);
}

export function AppBreadcrumbs() {
    const breadcrumbs = useBreadcrumbs();

    return (
        <Breadcrumb>
            {breadcrumbs.map((crumb) => (
                <NavLink
                    to={crumb.to}
                    key={crumb.key}
                    className="mb-0"
                    activeProps={{
                        className: "breadcrumb-item",
                    }}
                >
                    {crumb.breadcrumb}
                </NavLink>
            ))}
        </Breadcrumb>
    );
}
