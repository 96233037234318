import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import { ChangeEventHandler, useCallback } from "react";

type Props = {
    skip: number;
    take: number;
    total: number;
    setSkip: (page: number) => void;
    setTake: (value: number) => void;
    onNextPage: () => void;
    onPrevPage: () => void;
};

const perPageOptions = [10, 15, 25, 50, 75];

export function TablePagination(props: Props) {
    const currentPage = Math.floor(props.skip / props.take) + 1;
    const totalPages = Math.ceil(props.total / props.take);
    const canFirstPage = currentPage > 1;
    const canLastPage = currentPage < totalPages;
    const canPrevPage = currentPage > 1;
    const canNextPage = currentPage < totalPages;

    const onPerPageChange = useCallback<ChangeEventHandler<HTMLSelectElement>>(
        (event) => {
            const value = parseInt(event.target.value);

            if (!isNaN(value)) {
                props.setTake(value);
            }
        },
        [props],
    );
    const onFirstPage = useCallback(() => {
        props.setSkip(1);
    }, [props]);
    const onLastPage = useCallback(() => {
        props.setSkip(totalPages);
    }, [props, totalPages]);

    return (
        <nav className="d-flex gap-3 align-items-center justify-content-center p-2">
            <span>Rows per page:</span>
            <div>
                <Form.Select size="sm" defaultValue={props.take} onChange={onPerPageChange}>
                    {!perPageOptions.includes(props.take) && (
                        <option value={props.take}>{props.take}</option>
                    )}
                    {perPageOptions.map((v) => (
                        <option key={v} value={v}>
                            {v}
                        </option>
                    ))}
                </Form.Select>
            </div>
            <span>
                {props.skip + 1}-{Math.min(props.skip + props.take, props.total)} of {props.total}
            </span>
            <Pagination size="sm" className="mb-0">
                <Pagination.First onClick={onFirstPage} disabled={!canFirstPage} />
                <Pagination.Prev onClick={props.onPrevPage} disabled={!canPrevPage} />
                <Pagination.Next onClick={props.onNextPage} disabled={!canNextPage} />
                <Pagination.Last onClick={onLastPage} disabled={!canLastPage} />
            </Pagination>
        </nav>
    );
}
