import { createRoute } from "@tanstack/react-router";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { GlossariesTable } from "@/components/translation/glossaries/GlossariesTable";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { useGetGlossaries } from "@/components/translation/glossaries/hooks/useGlossaries";

export default function GlossariesPage() {
    const { useParams } = glossariesPageRoute;
    const { gameId } = useParams();
    const getGlossaries = useGetGlossaries(gameId);

    return (
        <DataAwaiter2 {...getGlossaries}>
            {(data) => <GlossariesTable gameId={gameId!} data={data} />}
        </DataAwaiter2>
    );
}

export const glossariesPageRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "translation/glossaries",
    component: GlossariesPage,
});
