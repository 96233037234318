import { useCallback, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Stack from "react-bootstrap/Stack";
import { UserInfo } from "@/components/common/UserInfo";
import {
    useBlockUserMutation,
    useGetUsersQuery,
    useUnblockUserMutation,
} from "@/graphql/wizardry/users";
import { BooleanCell } from "@/components/common/BooleanCell";
import { useWizCreateUser } from "@/components/wizardry/users/CreateUserDialog";
import { DataTable2 } from "@/components/common/DataTable2";
import { ActionsCell } from "@/components/wizardry/users/ActionsCell";
import { User } from "./types";

type Props = {
    data: User[];
};

const columnHelper = createColumnHelper<User>();

export function UsersList(props: Props) {
    const { refetch, loading: refetchLoading } = useGetUsersQuery();
    const [blockUserMutation, { loading: blockLoading }] = useBlockUserMutation();
    const [unblockUserMutation, { loading: unblockLoading }] = useUnblockUserMutation();
    const loading = refetchLoading || blockLoading || unblockLoading;
    const wizCreateUser = useWizCreateUser();
    const onCreateUser = useCallback(async () => {
        await wizCreateUser();
        await refetch();
    }, [refetch, wizCreateUser]);
    const blockUser = useCallback(
        async (userId: string) => {
            await blockUserMutation({
                variables: { userId },
            });
            await refetch();
        },
        [blockUserMutation, refetch],
    );
    const unblockUser = useCallback(
        async (userId: string) => {
            await unblockUserMutation({
                variables: { userId },
            });
            await refetch();
        },
        [refetch, unblockUserMutation],
    );
    const columns = useMemo(() => {
        return [
            columnHelper.accessor("guid", {
                header: "User",
                size: 650,
                cell: (props) => <UserInfo user={props.row.original} />,
            }),
            columnHelper.accessor("isDisabled", {
                header: "Has access disabled",
                size: 100,
                cell: BooleanCell,
            }),
            columnHelper.display({
                header: "Actions",
                cell: (props) => (
                    <ActionsCell
                        {...props}
                        loading={loading}
                        blockUser={blockUser}
                        unblockUser={unblockUser}
                    />
                ),
            }),
        ];
    }, [blockUser, loading, unblockUser]);

    return (
        <Stack gap={2}>
            <div>
                <ButtonGroup>
                    <Button onClick={onCreateUser}>Create user</Button>
                </ButtonGroup>
            </div>
            <DataTable2
                data={props.data}
                columns={columns}
                // expandableRowsComponent={UserInfoExpanded}
                bsTableProps={{
                    striped: true,
                }}
            />
        </Stack>
    );
}
