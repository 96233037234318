import { ComponentProps } from "react";
import { Link } from "@tanstack/react-router";
import Nav from "react-bootstrap/Nav";

export function NavItem(props: ComponentProps<typeof Link>) {
    return (
        <Nav.Item className="mb-0">
            <Link
                to={props.to}
                from={props.from}
                className="nav-link"
                activeProps={{ className: "active" }}
                activeOptions={{ exact: true }}
            >
                {props.children}
            </Link>
        </Nav.Item>
    );
}
