import { useCallback } from "react";
import { CreateGlossaryEntryMutationVariables } from "../../../../gql";
import { TranslationData } from "../../types";
import { Locale } from "../../../../types";
import { useCreateGlossaryEntryMutation } from "../../../../graphql/translation/glossaries";

export function useAddGlossary(locales: Locale[]) {
    const [createGlossaryEntryMutation] = useCreateGlossaryEntryMutation();

    return useCallback(
        async (row: TranslationData) => {
            const variables: CreateGlossaryEntryMutationVariables = {
                gameId: row.gameId,
                glossaryEntries: Object.entries(row.values)
                    .map(([code, value]) => {
                        const locale = locales.find((locale) => locale.code === code);
                        if (!locale) return null;
                        return { localeId: locale.guid, value };
                    })
                    .filter(Boolean),
            };
            await createGlossaryEntryMutation({ variables });
        },
        [createGlossaryEntryMutation, locales],
    );
}
