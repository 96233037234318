import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import isNil from "lodash/isNil";
import { stringify } from "@/utils/stringify";
import { JobModel } from "@/gql";

type Props = {
    data: JobModel;
};

const JobDetailsModal = create(function JobDetailsModal(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);

    const isFailed = props.data.failedReason || props.data.stacktrace.length > 0;
    const failedReason =
        props.data.failedReason !== "" && !isNil(props.data.failedReason)
            ? props.data.failedReason
            : "unknown";

    return (
        <Modal {...dialog} size="xl">
            <Modal.Header>
                <Modal.Title>Create user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack gap={2} className="p-4">
                    <div>
                        <h5>Job data</h5>
                        <pre>{stringify(props.data.data)}</pre>
                    </div>
                    <hr className="my-1" />
                    {isFailed && (
                        <div>
                            <h5>Error</h5>
                            <p>Reason: {failedReason}</p>
                            {props.data.stacktrace.length > 0 && (
                                <pre>{props.data.stacktrace.join("\n")}</pre>
                            )}
                        </div>
                    )}
                </Stack>
            </Modal.Body>
        </Modal>
    );
});

export function useJobDetailsModal() {
    return useCallback(
        (props: Props) => show<void, NiceModalHocProps & Props, Props>(JobDetailsModal, props),
        [],
    );
}
