import { SubmitHandler, useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useProtectDirtyForm } from "@/hooks/useProtectDirtyForm";

type Props = { categoryName?: string };
type FormData = Required<Props>;

const validationRules = {
    required: { value: true, message: "This field is required" },
    min: 3,
    max: 250,
};

const RenameCategoryModal = create(function RenameCategory(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const form = useForm<FormData>({
        defaultValues: {
            categoryName: props.categoryName ?? "",
        },
    });
    const modalProtectionProps = useProtectDirtyForm(form);
    const onSubmit = useCallback<SubmitHandler<FormData>>(
        (data) => {
            modal.resolve(data);
            return modal.hide();
        },
        [modal],
    );

    return (
        <Modal {...dialog} {...modalProtectionProps}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>Create category</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        placeholder="Category name"
                        {...form.register("categoryName", validationRules)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit">Save</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export function useRenameCategoryModal() {
    return useCallback(
        (props: Props) =>
            show<FormData, NiceModalHocProps & Props, Props>(RenameCategoryModal, props),
        [],
    );
}
