import { useEffect } from "react";
import { createRoute } from "@tanstack/react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Artifact } from "@/components/deploy";
import { withRBAC } from "@/components/rbac";
import { DeployLockType, Permission } from "@/gql";
import { useDeploy } from "@/hooks/useDeploy";
import { useDeployLock } from "@/hooks/useDeployLock";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useArtifacts } from "@/components/deploy/hooks/useArtifacts";
import { artifactRoute } from "@/pages/Artifacts";

function ArtifactsListPage() {
    const { useParams } = artifactsListPage;
    const { gameId } = useParams();
    const { onComplete } = useDeploy();
    const artifacts = useArtifacts(gameId!);
    const [, deployLocked] = useDeployLock(gameId!, DeployLockType.Deploy);

    useEffect(() => {
        return onComplete(() => artifacts.artifacts.refetch());
    }, [artifacts.artifacts, onComplete]);

    return (
        <DataAwaiter2 {...artifacts.artifacts}>
            {(data) => (
                <Row className="g-2">
                    {data.artifacts.map((artifact) => (
                        <Col md={12} key={artifact.guid}>
                            <Artifact
                                deployLocked={deployLocked}
                                artifact={artifact}
                                deployDirectories={data.deployDirectories}
                                onCreateDeployDirectory={artifacts.onCreateDeployDirectory}
                                onUpdate={(updateData) =>
                                    artifacts.onUpdateArtifact({
                                        guid: artifact.guid,
                                        ...updateData,
                                    })
                                }
                                onDeploy={(deployDirectoryId) =>
                                    artifacts.onDeployArtifact(artifact.guid, deployDirectoryId)
                                }
                                onDelete={() => artifacts.onDeleteArtifact(artifact.guid)}
                            />
                        </Col>
                    ))}
                </Row>
            )}
        </DataAwaiter2>
    );
}

const ArtifactsListPageRBAC = withRBAC(ArtifactsListPage, {
    requiredPermissions: [Permission.CanDeploy],
});
export default ArtifactsListPageRBAC;
export const artifactsListPage = createRoute({
    getParentRoute: () => artifactRoute,
    path: "/",
    component: ArtifactsListPageRBAC,
});
