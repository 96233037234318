import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useSnackbar } from "notistack";
import { FaClone, FaLock, FaTrash } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import { createColumnHelper } from "@tanstack/react-table";
import { GameInfo, User } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { useCloneGameMutation, useDeleteGameMutation } from "@/graphql/wizardry/games";
import { useSelectUserModal } from "@/components/wizardry/games/SelectUserModal";
import { showDeployLockModal } from "@/components/modals/DeployLockModal";
import { DateDisplay } from "@/components/common/DateDisplay";
import { UserInfoAsync } from "@/components/common/UserInfoAsync";
import { DataTable2 } from "@/components/common/DataTable2";
import { GameInfoExpanded } from "@/components/wizardry/games/GameInfoExpanded";

type Props = {
    data: GameInfo[];
};

// type GamesTable = TableProps<GameInfo>;
// type HandleRowSelected = Required<GamesTable>["onSelectedRowsChange"];

const columnHelper = createColumnHelper<GameInfo>();

const selectionColumn = columnHelper.display({
    id: "selection",
    size: 50,
    header: (props) => {
        const { table } = props;

        return (
            <Form.Check
                checked={table.getIsAllPageRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
            />
        );
    },
    cell: (props) => (
        <div className="px-1">
            <Form.Check
                checked={props.row.getIsSelected()}
                disabled={!props.row.getCanSelect()}
                onChange={props.row.getToggleSelectedHandler()}
            />
        </div>
    ),
});

export function GamesList(props: Props) {
    const [deleteGameMutation] = useDeleteGameMutation();
    const [cloneGameMutation] = useCloneGameMutation();
    const [rowSelection, onRowSelectionChange] = useState({});
    const [selectedRows, setSelectedRows] = useState<GameInfo[]>([]);
    const [filterValue, setFilterValue] = useState("");
    const onFilterValueChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (event) => setFilterValue(event.target.value),
        [],
    );
    const { enqueueSnackbar } = useSnackbar();
    const selectUserModal = useSelectUserModal();
    const onDeleteSelectedProjects = useCallback(async () => {
        const result = await deleteGameMutation({
            variables: { gameIds: selectedRows.map((row) => row.guid) },
        });

        if (result.data) {
            enqueueSnackbar("Deletion queued", {
                variant: "success",
                autoHideDuration: 5000,
            });
        }
    }, [deleteGameMutation, enqueueSnackbar, selectedRows]);
    const onRemoveProject = useCallback(
        async (guid: string) => {
            await deleteGameMutation({
                variables: { gameIds: [guid] },
            });
        },
        [deleteGameMutation],
    );
    const onCloneProject = useCallback(
        async (gameId: string) => {
            const selectedUser = await selectUserModal();

            if (!selectedUser) {
                return;
            }

            await cloneGameMutation({
                variables: {
                    gameId,
                    userId: (selectedUser as User).guid,
                },
            });
        },
        [cloneGameMutation, selectUserModal],
    );
    const onSetDeployLock = useCallback(async (gameId: string) => {
        return showDeployLockModal({ gameId });
    }, []);
    // const handleRowSelected = useCallback<HandleRowSelected>((state) => {
    //     setSelectedRows(state.selectedRows);
    // }, []);
    const onCreateProject = useCallback(() => {}, []);
    const displayData = useMemo(() => {
        if (filterValue.trim().length < 2) return props.data;
        const filter = filterValue.toLowerCase();
        return props.data.filter((row) => row.guid.includes(filter) || row.name.includes(filter));
    }, [filterValue, props.data]);
    const columns = useMemo(
        () => [
            selectionColumn,
            columnHelper.accessor("guid", {
                header: "Name",
                cell: (props) => (
                    <div className="d-flex flex-column">
                        <div>{props.row.original.name}</div>
                        <div className="small">{props.getValue()}</div>
                    </div>
                ),
            }),
            columnHelper.accessor("creatorId", {
                header: "Creator/Owner",
                cell: (props) => <UserInfoAsync userId={props.getValue()} />,
            }),
            columnHelper.accessor("createdAt", {
                header: "Created at",
                cell: (props) => <DateDisplay date={props.getValue()} />,
            }),
            columnHelper.accessor("deletedAt", {
                header: "Deleted at",
                cell: (props) => {
                    const deletedAt = props.getValue();

                    return deletedAt ? <DateDisplay date={deletedAt} /> : <span>&mdash;</span>;
                },
            }),
            columnHelper.display({
                header: "Actions",
                cell: (props) => {
                    const row = props.row.original;

                    return (
                        <ButtonGroup size="sm">
                            <ConfirmationButton
                                variant="danger"
                                onConfirm={() => onRemoveProject(row.guid)}
                                prompt="Are you sure you want to remove this project? This action is permanent and cannot be undone"
                                confirmationPhrase={row.name}
                                title="Delete"
                            >
                                <FaTrash />
                            </ConfirmationButton>
                            <Button
                                variant="warning"
                                onClick={() => onCloneProject(row.guid)}
                                title="Clone"
                            >
                                <FaClone />
                            </Button>
                            <Button onClick={() => onSetDeployLock(row.guid)} title="Deploy lock">
                                <FaLock />
                            </Button>
                        </ButtonGroup>
                    );
                },
            }),
        ],
        [onCloneProject, onRemoveProject, onSetDeployLock],
    );

    return (
        <div>
            <div className="d-flex align-items-start gap-2 py-2">
                <ButtonGroup size="sm" className="w-100">
                    <Form.Group className="w-100">
                        <Form.Control
                            placeholder="Filter"
                            value={filterValue}
                            onChange={onFilterValueChange}
                        />
                        <Form.Text>Filter is case sensitive</Form.Text>
                    </Form.Group>
                    <Button onClick={onCreateProject} variant="success">
                        Create project
                    </Button>
                    <ConfirmationButton
                        variant="danger"
                        onConfirm={onDeleteSelectedProjects}
                        disabled={Object.keys(rowSelection).length === 0}
                        prompt="Please confirm that you want to delete selected projects. Confirmation will actually delete them from the database"
                    >
                        Delete selected projects
                    </ConfirmationButton>
                </ButtonGroup>
            </div>
            <DataTable2
                data={displayData}
                columns={columns}
                expandableRowsComponent={GameInfoExpanded}
                tableOptions={{
                    enableRowSelection: true,
                    state: {
                        rowSelection,
                    },
                    onRowSelectionChange,
                }}
            />
        </div>
    );
}
