import { useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaEdit, FaTrash } from "react-icons/fa";
import { TemplateParam } from "@/gql";
import { CasedButton } from "@/components/common/CasedButton";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { DataTable2 } from "@/components/common/DataTable2";

type TemplateParam2 = Omit<TemplateParam, "consumers" | "template" | "entityParameters">;

type GlobalParamsTableProps = {
    loading: boolean;
    globalParameters: TemplateParam2[];
    onEdit: (guid: string) => void;
    onRemove: (guid: string) => void;
};

const columnHelper = createColumnHelper<TemplateParam2>();

export function GlobalParamsTable(props: GlobalParamsTableProps) {
    const { onEdit, onRemove, globalParameters, loading } = props;
    const columns = useMemo(
        () => [
            columnHelper.accessor("guid", {
                header: "Param name",
                minSize: 300,
                cell: (props) => (
                    <CasedButton
                        variant="link"
                        className="py-0 px-1"
                        onClick={() => onEdit(props.getValue())}
                    >
                        {props.row.original.name}
                    </CasedButton>
                ),
            }),
            columnHelper.accessor("type", { header: "Type" }),
            columnHelper.accessor("description", { header: "Description", minSize: 500 }),
            columnHelper.display({
                id: "global-params-actions",
                maxSize: 75,
                cell: (props) => (
                    <ButtonGroup size="sm">
                        <Button
                            disabled={loading}
                            onClick={() => onEdit(props.row.original.guid)}
                            variant="warning"
                            title="Edit"
                        >
                            <FaEdit />
                        </Button>
                        <ConfirmationButton
                            disabled={loading}
                            prompt="Are you sure you want to remove this parameter?"
                            onConfirm={() => onRemove(props.row.original.guid)}
                            variant="danger"
                            confirmationPhrase={props.row.original.name}
                            title="Remove"
                        >
                            <FaTrash />
                        </ConfirmationButton>
                    </ButtonGroup>
                ),
            }),
        ],
        [loading, onEdit, onRemove],
    );

    return <DataTable2 columns={columns} data={globalParameters} />;
}
