import { useCallback } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { CellContext } from "@tanstack/react-table";
import { useEditUserPermissionsModal } from "./modals/EditUserPermissions";
import { User } from "./types";

type Props = CellContext<User, unknown> & {
    loading: boolean;
    blockUser(guid: string): void;
    unblockUser(guid: string): void;
};

export function ActionsCell(props: Props) {
    const { blockUser, unblockUser, loading } = props;
    const editUserPermissionsModal = useEditUserPermissionsModal();
    const data = props.row.original;
    const onBlockUser = useCallback(() => blockUser(data.guid), [blockUser, data.guid]);
    const onUnblockUser = useCallback(() => unblockUser(data.guid), [data.guid, unblockUser]);
    const onEditPermissions = useCallback(
        () => editUserPermissionsModal({ data }),
        [data, editUserPermissionsModal],
    );

    return (
        <ButtonGroup size="sm">
            {!data.isDisabled && (
                <Button disabled={loading} variant="danger" onClick={onBlockUser}>
                    Block
                </Button>
            )}
            {data.isDisabled && (
                <Button disabled={loading} variant="danger" onClick={onUnblockUser}>
                    Unblock
                </Button>
            )}
            <Button onClick={onEditPermissions}>Permissions</Button>
        </ButtonGroup>
    );
}
