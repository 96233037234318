import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import { withRBAC } from "@/components/rbac";
import { Permission } from "@/gql";
import { AssetsTable } from "@/components/assets";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

const Assets = withRBAC(
    function Assets() {
        const { useParams } = assetsRoute;
        const { gameId } = useParams();

        if (!gameId) return null;

        return (
            <>
                <Helmet title="Assets" />
                <AssetsTable gameId={gameId} />
            </>
        );
    },
    {
        requiredPermissions: [Permission.DataWrite, Permission.DataRead],
        oneOf: true,
    },
);

export default Assets;

export const assetsRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "assets",
    component: Assets,
});
