import { Outlet, createRoute } from "@tanstack/react-router";
import Nav from "react-bootstrap/Nav";
import { Helmet } from "react-helmet-async";
import { Permission } from "@/gql";
import { withRBAC } from "@/components/rbac";
import { NavItem } from "@/components/common/NavItem";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

export function Artifacts() {
    const { useParams } = artifactRoute;
    const { gameId } = useParams();

    return (
        <>
            <Helmet title="Artifacts" />
            <Nav variant="pills" defaultActiveKey="/home">
                <NavItem to={`/${gameId}/artifacts`}>Artifacts</NavItem>
                <NavItem to={`/${gameId}/artifacts/history`}>Actions History</NavItem>
                <NavItem to={`/${gameId}/artifacts/diff`}>Diff</NavItem>
            </Nav>

            <Outlet />
        </>
    );
}

const ArtifactRBAC = withRBAC(Artifacts, {
    requiredPermissions: [Permission.CanDeploy],
});
export default ArtifactRBAC;
export const artifactRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "artifacts",
    component: ArtifactRBAC,
});
