import { Navigate, Outlet, createRoute } from "@tanstack/react-router";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Helmet } from "react-helmet-async";
import { FaArrowLeft } from "react-icons/fa";
import { useGetUser } from "@/graphql/auth";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { NavItem } from "@/components/common/NavItem";
import { startPage } from "@/pages/StartPage";
import { wizardryUsersRoute } from "@/pages/wizardry/UsersWizardry";
import { pandorasBoxWizardry } from "@/pages/wizardry/PandorasBoxWizardry";
import { wizardryGamesRoute } from "@/pages/wizardry/GamesWizardry";
import { wizardryDeployLocksRoute } from "@/pages/wizardry/DeployLocks";

export default function WizardryLayout() {
    const getUser = useGetUser();

    return (
        <DataAwaiter2 {...getUser}>
            {(data) =>
                data.result.canDoWizardry ? (
                    <Container className="pt-5">
                        <Helmet title="Wizardry" />
                        <a href="/games">
                            <FaArrowLeft /> Back to dashboard
                        </a>
                        <Nav variant="tabs" className="mb-2">
                            <NavItem from={wizardryRoute.to} to={wizardryGamesRoute.to}>
                                Games
                            </NavItem>
                            <NavItem from={wizardryRoute.to} to={wizardryUsersRoute.to}>
                                Users
                            </NavItem>
                            <NavItem from={wizardryRoute.to} to={pandorasBoxWizardry.to}>
                                Pandora&lsquo;s box
                            </NavItem>
                            <NavItem from={wizardryRoute.to} to={wizardryDeployLocksRoute.to}>
                                Deploy locks
                            </NavItem>
                        </Nav>
                        <Outlet />
                    </Container>
                ) : (
                    <Navigate to="/403" />
                )
            }
        </DataAwaiter2>
    );
}
export const wizardryRoute = createRoute({
    getParentRoute: () => startPage,
    path: "wizardry",
    component: WizardryLayout,
});
