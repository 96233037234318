import { gql } from "@apollo/client";
import { WizGetDeployLocksQuery } from "@/gql";
import { makeGqlQueryHook } from "../../util";

const SCHEMA = gql`
    query wizGetDeployLocks {
        wizGetDeployLocks {
            key
            ttl
            gameId
            artifactId
        }
    }
`;

export const useWizGetDeployLocks = makeGqlQueryHook<WizGetDeployLocksQuery>(SCHEMA, {
    pollInterval: 5000,
});
