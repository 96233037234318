import Stack from "react-bootstrap/Stack";
import { CellContext } from "@tanstack/react-table";
import {
    Consumer,
    Maybe,
    TemplateParam,
    GetTemplateParameterConsumersQuery,
    GetTemplateParameterConsumersQueryVariables,
    ConsumerAssociation,
} from "@/gql";
import { gql, useQuery } from "@apollo/client";
import { CONSUMER_FRAGMENT } from "@/graphql/fragments/consumer.fragment";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";

const SCHEMA = gql`
    ${CONSUMER_FRAGMENT}

    query getTemplateParameterConsumers(
        $gameId: String!
        $association: ConsumerAssociation!
        $associationId: String!
    ) {
        consumersForAssociation(
            gameId: $gameId
            association: $association
            associationId: $associationId
        ) {
            ...Consumer
        }
    }
`;

export function TemplateParameterConsumers(
    props: CellContext<Omit<TemplateParam, "template" | "creator">, Maybe<Consumer[]>>,
) {
    const { gameId, guid } = props.row.original;
    const query = useQuery<
        GetTemplateParameterConsumersQuery,
        GetTemplateParameterConsumersQueryVariables
    >(SCHEMA, {
        variables: {
            gameId,
            associationId: guid,
            association: ConsumerAssociation.TemplateParam,
        },
    });

    return (
        <DataAwaiter2 {...query}>
            {(data) => {
                if (!data.consumersForAssociation) return <span>No consumers assigned</span>;

                return (
                    <Stack>
                        {data.consumersForAssociation.map((c) => (
                            <div key={c.guid} className="small">
                                {c.name}
                            </div>
                        ))}
                    </Stack>
                );
            }}
        </DataAwaiter2>
    );
}
