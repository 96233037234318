import { useCallback, useMemo } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { createColumnHelper } from "@tanstack/react-table";
import { GetLocalesQuery } from "@/gql";
import { useGetLocales } from "@/graphql/translation";
import { Locale } from "@/types";
import { DataTable2 } from "@/components/common/DataTable2";
import { TranslationPageLayoutNav } from "@/pages/translation/TranslationPageLayout";
import { LocaleNameCell } from "@/components/translation/locales/LocaleNameCell";
import { showCreateLocaleModal } from "../translations/modals/CreateLocaleModal";
import { LocalesPageActionsCell } from "./LocalesPageActionsCell";

type Props = {
    gameId: string;
    data: GetLocalesQuery;
};

const columnHelper = createColumnHelper<Locale>();

export function LocalesTable(props: Props) {
    const { gameId } = props;
    const getLocales = useGetLocales({ gameId });
    const onAddLocale = useCallback(async () => {
        await showCreateLocaleModal({ gameId });
        return getLocales.refetch();
    }, [gameId, getLocales]);
    const columns = useMemo(
        () => [
            columnHelper.accessor("guid", {
                header: "Actions",
                cell: LocalesPageActionsCell,
                size: 100,
            }),
            columnHelper.accessor("guid", {
                header: "Locale name",
                cell: LocaleNameCell,
                minSize: 250,
            }),
            columnHelper.accessor("code", {
                header: "Code",
                cell: (props) => props.getValue(),
            }),
        ],
        [],
    );

    return (
        <>
            <div className="d-flex justify-content-between">
                <TranslationPageLayoutNav />
                <ButtonGroup>
                    <Button onClick={onAddLocale}>Add</Button>
                </ButtonGroup>
            </div>
            <DataTable2
                data={props.data.locales}
                columns={columns}
                bsTableProps={{
                    striped: true,
                }}
            />
        </>
    );
}
