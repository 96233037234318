import { useCallback, useMemo } from "react";
import Badge from "react-bootstrap/Badge";
import { FaTimes } from "react-icons/fa";
import { type MultiValue } from "react-select";
import { createColumnHelper } from "@tanstack/react-table";
import { Permission } from "@/gql";
import { useRemoveUserPermissionsAction, useSetUserPermissionsAction } from "@/store/games/actions";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { permissionsList } from "@/components/roles-page/constants";
import { PermissionOption, UserPermission } from "@/components/roles-page/types";
import { RolesSelect } from "@/components/common/RolesSelect";
import { BooleanCell } from "@/components/common/BooleanCell";
import { useAuth } from "@/hooks/useAuth";
import { IsDisabledCell } from "@/components/roles-page/components/IsDisabledCell";
import { UserInfoAsync } from "@/components/common/UserInfoAsync";
import { DataTable2 } from "@/components/common/DataTable2";

const rolesOrder = [
    Permission.DataRead,
    Permission.StructureRead,
    Permission.DataWrite,
    Permission.StructureWrite,
    Permission.CanDeploy,
    Permission.RolesChange,
].reduce((acc, role, index) => ({ ...acc, [role]: index }), {} as Record<Permission, number>);

interface UsersTableProps {
    creatorId: string;
    userPermissions: UserPermission[];
    gameId: string;
    currentUserId: string;
}

const columnHelper = createColumnHelper<UserPermission>();

export function UsersTable(props: UsersTableProps) {
    const { user } = useAuth();
    const { gameId, currentUserId, creatorId } = props;
    const [setUserPermissionsAction] = useSetUserPermissionsAction();
    const [removeUserPermissionsAction] = useRemoveUserPermissionsAction();
    const userAvailablePermissions = useMemo(() => {
        return permissionsList.filter((p) => p.value === Permission.RolesChange);
    }, []);
    const onRemovePermission = useCallback(
        async (userId: string) => {
            await removeUserPermissionsAction({ userId, gameId });
        },
        [gameId, removeUserPermissionsAction],
    );
    const onPermissionsChange = useCallback(
        async (value: MultiValue<PermissionOption>, userId: string) => {
            await setUserPermissionsAction({
                userId,
                gameId,
                permissions: value.map((v) => v.value),
            });
        },
        [gameId, setUserPermissionsAction],
    );
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: "isDisabled",
                header: "Is disabled",
                maxSize: 50,
                cell: (props) => (
                    <IsDisabledCell
                        {...props}
                        isDisabled={props.row.original.isDisabled}
                        gameId={gameId}
                        currentUserId={currentUserId}
                    />
                ),
                minSize: 120,
            }),
            columnHelper.accessor("user.canDoWizardry", {
                header: "Wizard?",
                maxSize: 50,
                cell: BooleanCell,
            }),
            columnHelper.display({
                id: "email",
                header: "Email",
                minSize: 300,
                maxSize: 500,
                cell: (props) => {
                    const row = props.row.original;

                    return (
                        <span>
                            <UserInfoAsync userId={row.userId} />
                            {row.userId === creatorId && (
                                <Badge className="ms-1" bg="success">
                                    Creator
                                </Badge>
                            )}
                            {currentUserId === row.userId && (
                                <Badge className="ms-1" bg="info">
                                    You
                                </Badge>
                            )}
                        </span>
                    );
                },
            }),
            columnHelper.display({
                id: "permissions",
                header: "Permissions",
                minSize: 300,
                maxSize: 700,
                cell: (props) => {
                    const row = props.row.original;

                    const options =
                        row.userId === currentUserId && !user?.canDoWizardry
                            ? userAvailablePermissions
                            : permissionsList;
                    const optionsSorted = options.sort(
                        (a, b) => rolesOrder[a.value] ?? 0 - rolesOrder[b.value] ?? 0,
                    );
                    return (
                        <RolesSelect
                            userId={row.userId}
                            value={row.permissions.map<PermissionOption>((permission) => ({
                                value: permission,
                                label: permission,
                                isDisabled:
                                    permission === Permission.RolesChange &&
                                    row.userId === currentUserId,
                            }))}
                            options={optionsSorted}
                            onChange={(newValue) => onPermissionsChange(newValue, row.userId)}
                        />
                    );
                },
            }),
            columnHelper.display({
                id: "actions",
                header: "Actions",
                maxSize: 50,
                cell: (props) => {
                    const row = props.row.original;

                    return (
                        currentUserId !== row.userId && (
                            <ConfirmationButton
                                prompt="Are you sure you want to remove permissions for this user?"
                                onConfirm={() => onRemovePermission(row.userId)}
                                size="sm"
                                variant="danger"
                                title="Remove user permissions"
                            >
                                <FaTimes size={14} className="align-middle" />
                            </ConfirmationButton>
                        )
                    );
                },
            }),
        ],
        [
            creatorId,
            currentUserId,
            gameId,
            onPermissionsChange,
            onRemovePermission,
            user?.canDoWizardry,
            userAvailablePermissions,
        ],
    );

    return <DataTable2 columns={columns} data={props.userPermissions} />;
}
