import { useCallback } from "react";
import { useTranslationsPageContext } from "../TranslationsPageProvider";
import { Translation } from "../../types";
import { useRemoveTranslationMutation } from "../../../../graphql/translation";

export function useRemoveTranslation() {
    const { translations } = useTranslationsPageContext();
    const [removeTranslationMutation] = useRemoveTranslationMutation();

    return useCallback(
        async (row: Translation) => {
            await removeTranslationMutation({ variables: { gameId: row.gameId, guid: row.guid } });
            return translations.refetch();
        },
        [removeTranslationMutation, translations],
    );
}
