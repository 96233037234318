import { Helmet } from "react-helmet-async";
import { createRoute } from "@tanstack/react-router";
import { withRBAC } from "@/components/rbac";
import { Permission } from "@/gql";
import { TemplateDisplayPage } from "@/components/template-display-page";
import { SuspenseFallback } from "@/components/SuspenseFallback";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { templatePageRoute } from "@/pages/template/Template";
import { useTemplateContext } from "@/pages/template/TemplateProvider";

function TemplateDisplay() {
    const { useParams } = templateDisplayRoute;
    const { templateId, gameId } = useParams();
    const template = useTemplateContext();

    if (!templateId) return <SuspenseFallback />;

    return (
        <>
            <Helmet title="Display settings" />
            <DataAwaiter2 {...template}>
                {(data) => (
                    <TemplateDisplayPage
                        templateDisplay={data.template.templateDisplay!}
                        template={data.template}
                        gameId={gameId!}
                    />
                )}
            </DataAwaiter2>
        </>
    );
}

const TemplateDisplayRBAC = withRBAC(TemplateDisplay, {
    requiredPermissions: [
        Permission.DataWrite,
        Permission.DataRead,
        Permission.StructureWrite,
        Permission.StructureRead,
    ],
    oneOf: true,
});

export default TemplateDisplayRBAC;

export const templateDisplayRoute = createRoute({
    getParentRoute: () => templatePageRoute,
    path: "display",
    component: TemplateDisplayRBAC,
});
