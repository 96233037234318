import { gql } from "@apollo/client";
import { WizReleaseDeployLockMutation, WizReleaseDeployLockMutationVariables } from "@/gql";
import { makeGqlMutationHook } from "../../util";

const SCHEMA = gql`
    mutation wizReleaseDeployLock($key: String!) {
        wizReleaseDeployLock(key: $key)
    }
`;

export const useWizReleaseDeployLock = makeGqlMutationHook<
    WizReleaseDeployLockMutation,
    WizReleaseDeployLockMutationVariables
>(SCHEMA);
