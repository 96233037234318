import { useParams, createRoute } from "@tanstack/react-router";
import { gql } from "@apollo/client";
import { makeGqlQueryHook } from "@/graphql/util";
import { GetEntityDetailsQuery, GetEntityDetailsQueryVariables, Permission } from "@/gql";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { EntityPage } from "@/components/entity-page/EntityPage";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { withRBAC } from "@/components/rbac";

const SCHEMA = gql`
    query getEntityDetails($guid: String!, $gameId: String!) {
        entity(guid: $guid, gameId: $gameId) {
            guid
            gameId
            templateId
            template {
                name
            }
        }
        referencingParameters(guid: $guid, gameId: $gameId) {
            guid
            gameId
            templateId
            templateName
            templateParameterId
            templateParameterName
        }
    }
`;

const useEntity = makeGqlQueryHook<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>(SCHEMA);

const EntityPagePageRBAC = withRBAC(
    function EntityPagePage() {
        const { useParams } = entityPageRoute;
        const { entityId, gameId } = useParams();
        const entity = useEntity({ guid: entityId!, gameId: gameId! });

        return (
            <DataAwaiter2 {...entity}>
                {(data) => {
                    return (
                        <EntityPage
                            gameId={gameId!}
                            entityId={entityId!}
                            entity={data.entity}
                            referencingParameters={data.referencingParameters}
                        />
                    );
                }}
            </DataAwaiter2>
        );
    },
    {
        requiredPermissions: [Permission.DataRead],
    },
);
export default EntityPagePageRBAC;
export const entityPageRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "entity/$entityId",
    component: EntityPagePageRBAC,
});
