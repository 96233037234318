import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { SubmitHandler, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSnackbar } from "notistack";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";
import { ProgressButton } from "@/components/common/ProgressButton";
import { useCreateGameMutation } from "@/graphql/game";
import { CreateGameMutationVariables } from "@/gql";

const CreateGameModal = create(function CreateGameModal() {
    const { enqueueSnackbar } = useSnackbar();
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const [createGame] = useCreateGameMutation();
    const form = useForm<CreateGameMutationVariables>({
        defaultValues: {
            gameName: "",
            description: "",
        },
        mode: "onChange",
    });
    const onSubmit = useCallback<SubmitHandler<CreateGameMutationVariables>>(
        async (data) => {
            const result = await createGame({ variables: data });
            if (result.data) {
                modal.resolve();
                return modal.hide();
            } else {
                enqueueSnackbar("Failed to create project", { variant: "error" });
            }
        },
        [createGame, enqueueSnackbar, modal],
    );

    return (
        <Modal {...dialog}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>Create game</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-4">
                        <Col md={12}>
                            <FormInput
                                control={form.control}
                                title="Game name"
                                name="gameName"
                                rules={{ required: true }}
                            />
                        </Col>
                        <Col md={12}>
                            <FormInput
                                control={form.control}
                                title="Game description"
                                name="description"
                                rules={{ required: true, max: 1024 }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ProgressButton
                        type="submit"
                        variant="danger"
                        disabled={!form.formState.isValid}
                        loading={form.formState.isSubmitting}
                    >
                        Create
                    </ProgressButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export function useCreateGameModal() {
    return useCallback(() => show(CreateGameModal), []);
}
