import { useCallback, useMemo } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { type MultiValue } from "react-select";
import { RolesSelect } from "@/components/common/RolesSelect";
import { permissionsList } from "@/components/roles-page/constants";
import { PermissionOption } from "@/components/roles-page/types";
import { useSetUserPermissionsForProject } from "@/graphql/wizardry/users";
import { DataTable2 } from "@/components/common/DataTable2";
import { User, UserPermission } from "./types";

const columnHelper = createColumnHelper<UserPermission>();

type Props = {
    data: User;
};

export function UserInfoExpanded(props: Props) {
    const [setUserPermissionsForProject] = useSetUserPermissionsForProject();
    const onPermissionsChange = useCallback(
        async (value: MultiValue<PermissionOption>, userId: string, gameId: string) => {
            await setUserPermissionsForProject({
                variables: {
                    userId,
                    gameId,
                    permissions: value.map((v) => v.value),
                },
            });
        },
        [setUserPermissionsForProject],
    );
    const columns = useMemo(
        () => [
            columnHelper.accessor("game.name", {
                header: "Project name",
                size: 350,
            }),
            columnHelper.display({
                header: "Project permissions",
                size: 650,
                cell: (props) => {
                    const data = props.row.original;
                    const permissionsValue = data.permissions.map<PermissionOption>((p) => ({
                        value: p,
                        label: p,
                        isDisabled: false,
                    }));
                    return (
                        <RolesSelect
                            userId={data.userId}
                            options={permissionsList}
                            onChange={(value) =>
                                onPermissionsChange(value, data.userId, data.gameId)
                            }
                            value={permissionsValue}
                        />
                    );
                },
            }),
        ],
        [onPermissionsChange],
    );

    return (
        <DataTable2
            data={props.data.userPermissions!}
            columns={columns}
            bsTableProps={{ striped: true }}
        />
    );
}
