import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FaEdit, FaTrash } from "react-icons/fa";
import DropdownButton from "react-bootstrap/DropdownButton";
import { MdTranslate } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import { CellContext } from "@tanstack/react-table";
import { useCallback } from "react";
import { ConfirmationButton } from "../../../common/ConfirmationButton";
import { TranslationData } from "../../types";
import { useUpdateTranslationFromDeepl } from "../hooks/useUpdateTranslationFromDeepl";
import { useEditTranslation } from "../hooks/useEditTranslation";
import { useTranslationsPageContext } from "../TranslationsPageProvider";
import { useRemoveTranslation } from "../hooks/useRemoveTranslation";
import { useAddGlossary } from "../hooks/useAddGlossary";

export function ActionsColumnCell(props: CellContext<TranslationData, "guid">) {
    const gameId = props.row.original.gameId;
    const { translations } = useTranslationsPageContext();
    const locales = translations.data?.locales ?? [];
    const editTranslation = useEditTranslation(gameId, locales);
    const removeTranslation = useRemoveTranslation();
    const updateTranslationFromDeepl = useUpdateTranslationFromDeepl();
    const addToGlossary = useAddGlossary(locales);
    const onUpdateTranslationFromDeepl = useCallback(
        () => updateTranslationFromDeepl(props.row.original),
        [props.row.original, updateTranslationFromDeepl],
    );
    const onRemoveTranslation = useCallback(
        () => removeTranslation(props.row.original),
        [props.row.original, removeTranslation],
    );
    const onAddToGlossary = useCallback(
        () => addToGlossary(props.row.original),
        [addToGlossary, props.row.original],
    );
    const onEditTranslation = useCallback(
        () => editTranslation(props.row.original),
        [editTranslation, props.row.original],
    );

    return (
        <div className="d-flex flex-column align-items-start">
            <ButtonGroup size="sm">
                <Button onClick={onEditTranslation} title="Edit">
                    <FaEdit />
                </Button>
                <ConfirmationButton onConfirm={onRemoveTranslation} variant="danger" title="Remove">
                    <FaTrash />
                </ConfirmationButton>
                <DropdownButton title={<MdTranslate />} size="sm">
                    <Dropdown.Item
                        as={ConfirmationButton}
                        variant="link"
                        size="sm"
                        onConfirm={onUpdateTranslationFromDeepl}
                        prompt="Are you sure you want to update this row with translations from Deepl"
                        title="Update with Deepl"
                    >
                        Update with Deepl
                    </Dropdown.Item>
                    <Dropdown.Item as={Button} variant="link" onClick={onAddToGlossary}>
                        Add to glossary
                    </Dropdown.Item>
                </DropdownButton>
            </ButtonGroup>
        </div>
    );
}
