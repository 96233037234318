import { CellContext } from "@tanstack/react-table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useCallback } from "react";
import { stringify } from "@/utils/stringify";
import { DeployDirectory } from "@/gql";
import { useFilePreviewModal } from "../modals/FilePreviewModal";
import { ConsumerArtifactData } from "./types";

export function ArtifactActionsCell(
    props: CellContext<ConsumerArtifactData, unknown> & { deployedOn: DeployDirectory[] },
) {
    const { deployedOn } = props;
    const artifactData = props.row.original;
    const showFilePreviewModal = useFilePreviewModal();
    const onDetailsClicked = useCallback(async () => {
        await showFilePreviewModal({
            data: artifactData.data,
            fileName: artifactData.name,
        });
    }, [artifactData.data, artifactData.name, showFilePreviewModal]);
    const onDetailsDownload = useCallback(async () => {
        try {
            // @ts-ignore
            const fsHandle = await window.showSaveFilePicker({
                suggestedName: artifactData.name,
            });
            const writable = await fsHandle.createWritable();
            await writable.write(stringify(artifactData.data, "__typename", "bucketPath"));
            await writable.close();
        } catch (e) {
            alert(
                "Failed to save file. This either means that you have an older browser or the API has changed",
            );
        }
    }, [artifactData.data, artifactData.name]);

    return (
        <ButtonGroup size="sm">
            <Button onClick={onDetailsClicked}>Preview</Button>
            <Button onClick={onDetailsDownload}>Download</Button>
            {artifactData.bucketPath &&
                deployedOn.map((dd) => {
                    const path = artifactData.bucketPath?.replace("%DD%", dd.path);
                    return (
                        <Button
                            key={dd.guid}
                            href={path}
                            title={path}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Open in bucket: {dd.name}
                        </Button>
                    );
                })}
        </ButtonGroup>
    );
}
