import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "@tanstack/react-router";
import { templateStructureRoute } from "@/pages/template/TemplateStructure";
import { useCreateTemplate } from "./modals/CreateTemplateModal";

interface CreateTemplateButtonProps {
    gameId: string;
}

export function CreateTemplateButton(props: CreateTemplateButtonProps) {
    const { gameId } = props;
    const navigate = useNavigate();
    const openCreateTemplateModal = useCreateTemplate();

    const onCreateTemplate = useCallback(async () => {
        const newTemplate = await openCreateTemplateModal({ gameId });

        if (newTemplate) {
            return navigate({
                to: templateStructureRoute.fullPath,
                params: {
                    gameId,
                    templateId: newTemplate.guid,
                },
            });
        }
    }, [gameId, navigate, openCreateTemplateModal]);

    return (
        <Button size="sm" variant="success" onClick={onCreateTemplate}>
            <FaPlus size={14} className="align-middle me-1" />
            <span className="align-middle">Add template</span>
        </Button>
    );
}
