import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { useUploadAsset } from "@/components/assets/hooks/useUploadAsset";
import { Upload } from "@/gql";
import { ProgressButton } from "@/components/common/ProgressButton";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";
import { AssetRawData } from "../types";

type Props = {
    gameId: string;
    asset?: AssetRawData;
};

const EditAssetModal = create(function EditAssetModal(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const uploadAsset = useUploadAsset();
    const form = useForm<AssetRawData>({
        defaultValues: {
            name: "",
            description: "",
            gameId: props.gameId,
            file: null,
        },
    });
    const onSubmit = useCallback<SubmitHandler<AssetRawData>>(
        async (data) => {
            const result = await uploadAsset(data);
            modal.resolve(result);
            return modal.hide();
        },
        [modal, uploadAsset],
    );

    return (
        <Modal {...dialog} backdrop="static" keyboard={false}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.asset ? "Edit asset" : "Create asset"}</Modal.Title>
                </Modal.Header>
                <FormProvider {...form}>
                    <Stack as={Modal.Body} gap={2}>
                        <FormInput
                            name="name"
                            control={form.control}
                            title="Asset name"
                            rules={{
                                required: true,
                                min: 3,
                                max: 250,
                            }}
                        />
                        <FormInput
                            control={form.control}
                            name="description"
                            title="Asset description"
                        />

                        <input type="file" {...form.register("file", { required: true })} />
                    </Stack>
                    <Modal.Footer>
                        <ProgressButton
                            type="submit"
                            color="danger"
                            loading={form.formState.isSubmitting}
                            disabled={!form.formState.isValid}
                        >
                            {props.asset ? "Save asset" : "Upload asset"}
                        </ProgressButton>
                    </Modal.Footer>
                </FormProvider>
            </Form>
        </Modal>
    );
});

export function useEditAssetModal() {
    return useCallback((props: Props) => {
        return show<Upload, NiceModalHocProps & Props, Props>(EditAssetModal, props);
    }, []);
}
