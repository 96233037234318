import { useCallback, useMemo } from "react";
import { createRoute } from "@tanstack/react-router";
import { createColumnHelper } from "@tanstack/react-table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { FaTrash } from "react-icons/fa";
import { useWizGetDeployLocks, useWizReleaseDeployLock } from "@/graphql/wizardry/deploy-locks";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { DataTable2 } from "@/components/common/DataTable2";
import { WizDeployLock } from "@/gql";
import { wizardryRoute } from "./WizardryLayout";

const columnHelper = createColumnHelper<WizDeployLock>();

export function DeployLocks() {
    const [releaseDeployLock] = useWizReleaseDeployLock();
    const getDeployLocks = useWizGetDeployLocks();
    const onReleaseDeployLock = useCallback(
        async (key: string) => {
            await releaseDeployLock({ variables: { key } });
            await getDeployLocks.refetch();
        },
        [getDeployLocks, releaseDeployLock],
    );
    const columns = useMemo(
        () => [
            columnHelper.accessor("key", {
                id: "key",
                header: "Lock key",
                size: 250,
            }),
            columnHelper.accessor("gameId", {
                id: "projectId",
                header: "Project id",
                size: 300,
                cell: (props) => <span className="small">{props.getValue()}</span>,
            }),
            columnHelper.accessor("artifactId", {
                id: "artifactId",
                header: "Artifact id",
                size: 300,
                cell: (props) => <span className="small">{props.getValue()}</span>,
            }),
            columnHelper.accessor("key", {
                id: "actions",
                header: "Actions",
                size: 50,
                cell: (props) => (
                    <ButtonGroup size="sm">
                        <ConfirmationButton onConfirm={() => onReleaseDeployLock(props.getValue())}>
                            <FaTrash />
                        </ConfirmationButton>
                    </ButtonGroup>
                ),
            }),
        ],
        [onReleaseDeployLock],
    );

    return (
        <DataAwaiter2 {...getDeployLocks}>
            {(data) =>
                data.wizGetDeployLocks.length === 0 ? (
                    "No deploy locks"
                ) : (
                    <DataTable2 data={data.wizGetDeployLocks} columns={columns} />
                )
            }
        </DataAwaiter2>
    );
}

export const wizardryDeployLocksRoute = createRoute({
    getParentRoute: () => wizardryRoute,
    path: "deploy-locks",
    component: DeployLocks,
});
