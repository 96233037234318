import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import { useFieldArray, useForm } from "react-hook-form";
import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { FaPlus, FaTrash } from "react-icons/fa";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { useSnackbar } from "notistack";
import { ExportGlossariesMutation } from "@/gql";
import { useGetLocales } from "@/graphql/translation";
import { useAuth } from "@/hooks/useAuth";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useShowExportResultModal } from "@/components/modals/ExportResultModal";
import { useExportGlossariesMutation } from "@/graphql/translation/glossaries";
import { Locale } from "@/types";

type Props = {
    locales: Locale[];
    gameId: string;
};

type FormData = {
    locales: Record<string, boolean>;
    emails: { value: string }[];
};

type ExportFormProps = {
    locales: Locale[];
    gameId: string;
    onSubmit: (data: ExportGlossariesMutation["exportGlossariesToSpreadsheet"]) => void;
};

function ExportForm(props: ExportFormProps) {
    const { locales, gameId } = props;
    const { user } = useAuth();
    const [exportGlossaries, { loading }] = useExportGlossariesMutation();
    const { enqueueSnackbar } = useSnackbar();
    const form = useForm<FormData>({
        mode: "onChange",
        defaultValues: {
            locales: Object.fromEntries(locales.map((locale) => [locale.guid, true])),
            emails: [{ value: user?.email ?? "" }],
        },
        disabled: loading,
    });
    const emailsArray = useFieldArray({
        control: form.control,
        name: "emails",
        rules: {
            minLength: 1,
        },
    });
    const onAdd = useCallback(() => {
        emailsArray.append({ value: "" });
    }, [emailsArray]);
    const onSubmit = useCallback(
        async (variables: FormData) => {
            const { data } = await exportGlossaries({
                variables: {
                    gameId,
                    locales: Object.entries(variables.locales)
                        .filter(([, v]) => v)
                        .map(([guid]) => guid),
                    emails: variables.emails.map((v) => v.value),
                },
            });
            if (!data) {
                enqueueSnackbar("Export failed", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
                return;
            }

            props.onSubmit(data.exportGlossariesToSpreadsheet);
        },
        [exportGlossaries, gameId, props, enqueueSnackbar],
    );

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            <Stack gap={4}>
                <div>
                    <p>Select locales to export</p>
                    {locales.map((locale) => (
                        <Form.Check
                            key={locale.guid}
                            {...form.register(`locales.${locale.guid}`)}
                            label={`${locale.name} (${locale.code})`}
                            disabled={loading}
                        />
                    ))}
                </div>
                <div>
                    <p>Emails that will have access to spreadsheet granted</p>
                    <Stack gap={2}>
                        {emailsArray.fields.map((field, index) => (
                            <InputGroup key={field.id} className="d-flex">
                                <Form.Control
                                    {...form.register(`emails.${index}.value`)}
                                    disabled={loading}
                                />
                                <Button
                                    onClick={() => emailsArray.remove(index)}
                                    variant="danger"
                                    disabled={loading}
                                >
                                    <FaTrash />
                                </Button>
                            </InputGroup>
                        ))}
                        <Button size="sm" onClick={onAdd} disabled={loading}>
                            <FaPlus /> Add
                        </Button>
                    </Stack>
                </div>
                <Button type="submit" disabled={loading}>
                    {loading && <Spinner size="sm" />} Export
                </Button>
            </Stack>
        </Form>
    );
}

function ExportGlossariesModalComponent(props: Props) {
    const { gameId } = props;
    const modal = useModal();
    const dialog = bootstrapDialog(modal);
    const getLocales = useGetLocales({ gameId });
    const showExportResultModal = useShowExportResultModal();
    const { enqueueSnackbar } = useSnackbar();
    const onSubmit = useCallback(
        async (data: ExportGlossariesMutation["exportGlossariesToSpreadsheet"]) => {
            if (data.spreadsheetId) {
                await showExportResultModal(data.spreadsheetId);
                enqueueSnackbar("Exported successfully", {
                    variant: "success",
                });
                return;
            } else {
                enqueueSnackbar("export failed", {
                    variant: "error",
                });
            }
        },
        [enqueueSnackbar, showExportResultModal],
    );

    return (
        <Modal {...dialog}>
            <Modal.Header>
                <Modal.Title>Export glossaries to spreadsheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataAwaiter2 {...getLocales}>
                    {(data) => (
                        <ExportForm locales={data.locales} onSubmit={onSubmit} gameId={gameId} />
                    )}
                </DataAwaiter2>
            </Modal.Body>
        </Modal>
    );
}

const ExportGlossariesModal = create(ExportGlossariesModalComponent);

export function showExportGlossariesModal(props: Props) {
    return show(ExportGlossariesModal, props);
}
