import {
    MouseEventHandler,
    FormEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Form from "react-bootstrap/Form";
import sortBy from "lodash/sortBy";
import { Link } from "@tanstack/react-router";
import styled from "styled-components";
import { TemplateCategoriesQuery } from "@/gql";
import { categoriesPageRoute } from "@/pages/CategoriesPage";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";
import { ScrollSpy } from "@/components/common/ScrollSpy";
import { TemplatesCategory } from "./TemplatesCategory";
import { CategoriesSidebarContextProvider } from "./CategoriesSidebarContext";
import { FavoriteTemplatesCategory } from "./FavoriteTemplatesCategory";

type Props = {
    className?: string;
    templateCategories: TemplateCategoriesQuery["templateCategories"];
    onItemSelected: () => void;
};

const ScrollSpyLink = styled.p`
    padding: 0.5rem;
    border-left: 2px solid transparent;
    transition: color 0.2s ease-in-out;
    margin: 0;

    &.active-scroll-spy {
        border-left-color: var(--bs-primary);
    }
`;

const ScrollSpyContainer = styled(ScrollSpy)`
    overflow: scroll;
`;

const TemplateCategoriesWrapper = styled.div`
    overflow: hidden;
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: 9fr 3fr;
    grid-gap: 0.25rem;
`;

const FilterInput = styled.div`
    grid-row: 1/2;
    grid-column: 1/3;
`;

const ManageCategories = styled.div`
    grid-row: 2/3;
    grid-column: 1/3;
`;

const CategoriesWrapper = styled.div`
    overflow-y: scroll;
    grid-row: 3/4;
    grid-column: 1/2;
`;

const NavContainer = styled.div`
    grid-row: 3/4;
    grid-column: 2/3;
`;

export function TemplateCategories(props: Props) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const filterRef = useRef<HTMLInputElement | null>(null);
    const [filterText, setFilterText] = useState("");
    const parentScrollContainerRef = useRef<HTMLDivElement | null>(null);
    const navContainerRef = useRef<HTMLDivElement | null>(null);
    // useSaveScrollPosition(parentScrollContainerRef);
    const onFilterEvent = useCallback<FormEventHandler<HTMLInputElement>>((event) => {
        setFilterText(event.currentTarget.value.trim().toLowerCase());
    }, []);
    const onClick = useCallback<MouseEventHandler<HTMLAnchorElement>>((e) => {
        e.preventDefault();
        const target = window.document.getElementById(e.currentTarget.href.split("#")[1]!);
        if (target) {
            const headerOffset = 120;
            const elementPosition = target.getBoundingClientRect().top;
            const offsetPosition = elementPosition - headerOffset;

            parentScrollContainerRef.current!.scrollBy({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }, []);
    const templateCategories = useMemo(() => {
        return props.templateCategories
            .map((tc) => {
                return {
                    ...tc,
                    templates: sortBy(
                        tc.templates.filter(
                            (template) =>
                                filterText.length < 2 ||
                                template.name.toLowerCase().includes(filterText),
                        ),
                        "name",
                    ),
                    scrollspyId: `category-${tc.name.replace(/\W/, "")}`,
                };
            })
            .filter((tc) => tc.templates.length > 0);
    }, [filterText, props.templateCategories]);

    useEffect(() => {
        filterRef.current?.focus();
    }, []);

    return (
        <TemplateCategoriesWrapper className={props.className}>
            <FilterInput>
                <Form.Control
                    ref={filterRef}
                    defaultValue={filterText}
                    onInput={onFilterEvent}
                    placeholder="Filter"
                />
            </FilterInput>
            <ManageCategories className="w-100 text-end">
                <Link
                    from={layoutWithBreadcrumbs.fullPath}
                    to={categoriesPageRoute.fullPath}
                    className="small"
                    onClick={props.onItemSelected}
                >
                    Manage categories
                </Link>
            </ManageCategories>
            <CategoriesWrapper ref={parentScrollContainerRef}>
                <CategoriesSidebarContextProvider gameId={gameId}>
                    <ScrollSpyContainer
                        useBoxMethod
                        navContainerRef={navContainerRef}
                        parentScrollContainerRef={parentScrollContainerRef}
                        updateHistoryStack={false}
                        scrollThrottle={300}
                        offsetTop={120}
                    >
                        <FavoriteTemplatesCategory
                            templateCategories={templateCategories}
                            onItemSelected={props.onItemSelected}
                        />
                        {templateCategories.map((category) => (
                            <TemplatesCategory
                                className="mb-4"
                                key={category.guid}
                                data={category}
                                onItemSelected={props.onItemSelected}
                            />
                        ))}
                    </ScrollSpyContainer>
                </CategoriesSidebarContextProvider>
            </CategoriesWrapper>
            <NavContainer ref={navContainerRef}>
                {templateCategories.map((category) => (
                    <a key={category.guid} href={`#${category.scrollspyId}`} onClick={onClick}>
                        <ScrollSpyLink data-to-scrollspy-id={category.scrollspyId}>
                            {category.name}
                        </ScrollSpyLink>
                    </a>
                ))}
            </NavContainer>
        </TemplateCategoriesWrapper>
    );
}
