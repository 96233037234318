import Button from "react-bootstrap/Button";
import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";

type Props = {
    spreadsheetId: string;
};

const ExportResultModal = create(function ExportResultModal(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const spreadsheetUrl = `https://docs.google.com/spreadsheets/d/${props.spreadsheetId}/edit#gid=0`;

    return (
        <Modal {...dialog}>
            <Modal.Body>
                <h3>Template data has been exported successfully</h3>
                <p>You can see the file by clicking the button below</p>
                <Button as="a" href={spreadsheetUrl} target="_blank">
                    Open spreadsheet
                </Button>
            </Modal.Body>
        </Modal>
    );
});

export function useExportResultModal() {
    return useCallback((props: Props) => show(ExportResultModal, props), []);
}
