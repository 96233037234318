import { useCallback } from "react";
import { type CellContext } from "@tanstack/react-table";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useRemoveGlossaryEntryMutation } from "@/graphql/translation/glossaries";
import { GlossaryEntryTransformed } from "../types";
import { ConfirmationButton } from "../../common/ConfirmationButton";
import { showEditGlossaryEntryModal } from "../translations/modals/EditGlossaryEntryModal";
import { useGetGlossaries } from "./hooks/useGlossaries";

export function GlossariesPageActionColumn(props: CellContext<GlossaryEntryTransformed, "guid">) {
    const gameId = props.row.original.gameId;
    const getGlossaries = useGetGlossaries(gameId);
    const [removeGlossaryEntryMutation] = useRemoveGlossaryEntryMutation();
    const onUpdateGlossaryEntry = useCallback(
        async (row: GlossaryEntryTransformed) => {
            await showEditGlossaryEntryModal({ entry: row, gameId });
            await getGlossaries.refetch();
        },
        [gameId, getGlossaries],
    );
    const onRemoveGlossaryEntry = useCallback(
        async (row: GlossaryEntryTransformed) => {
            await removeGlossaryEntryMutation({
                variables: { guid: row.guid, gameId: row.gameId },
            });
            await getGlossaries.refetch();
        },
        [getGlossaries, removeGlossaryEntryMutation],
    );

    return (
        <ButtonGroup size="sm">
            <Button
                disabled={getGlossaries.loading}
                onClick={() => onUpdateGlossaryEntry(props.row.original)}
                title="Edit"
            >
                <FaEdit />
            </Button>
            <ConfirmationButton
                disabled={getGlossaries.loading}
                onConfirm={() => onRemoveGlossaryEntry(props.row.original)}
                variant="danger"
                title="Remove"
            >
                <FaTrash />
            </ConfirmationButton>
        </ButtonGroup>
    );
}
