import { useCallback, useMemo } from "react";
import { createRoute } from "@tanstack/react-router";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Helmet } from "react-helmet-async";
import { useAvailableConsumers } from "@/store/games/slices";
import { TemplateParamsTable } from "@/components/common/Table/TemplateParamsTable";
import { Permission, QueueType, TemplateType } from "@/gql";
import { withRBAC } from "@/components/rbac";
import { useAddTemplateParametersToConsumerMutation } from "@/graphql/consumers";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useRemoveTemplateParamMutation } from "@/graphql/template-params";
import { useShowTemplateParamModal } from "@/components/modals/TemplateParamModal";
import { useJobStatusSubscription } from "@/graphql/queues";
import { useAddDescriptiveParameters } from "@/components/modals/AddDescriptiveParametersModal";
import { templatePageRoute } from "@/pages/template/Template";
import { useTemplateContext } from "@/pages/template/TemplateProvider";

const protectedParameterNames = new Set(["_Title", "_Description"]);

export function TemplateParameters() {
    const availableConsumers = useAvailableConsumers();
    const { useParams } = templateParametersRoute;
    const { gameId, templateId } = useParams();
    const getTemplate = useTemplateContext();
    const template = getTemplate.data?.template;
    const isDocumentType = template?.type === TemplateType.Document;
    const showTemplateParamModal = useShowTemplateParamModal();
    const hasDescriptiveParameters = useMemo(() => {
        if (!template?.templateParams) return false;

        const protectedParametersSet = new Set(protectedParameterNames);

        template.templateParams.forEach((tp) => {
            if (tp.meta.isProtected && protectedParameterNames.has(tp.name)) {
                protectedParametersSet.delete(tp.name);
            }
        });

        return protectedParametersSet.size === 0;
    }, [template]);
    useJobStatusSubscription({
        variables: {
            eventTypes: [QueueType.UpdateTemplateParam],
        },
        onData() {
            getTemplate.refetch();
        },
    });
    const [
        addTemplateParametersToConsumerMutation,
        { loading: addTemplateParametersToConsumerMutationLoading },
    ] = useAddTemplateParametersToConsumerMutation();
    const onCompleted = useCallback(() => {
        getTemplate.refetch();
    }, [getTemplate]);
    const [removeTemplateParameter, { loading: removeTemplateParameterLoading }] =
        useRemoveTemplateParamMutation({
            onCompleted,
        });
    const addDescriptionParams = useAddDescriptiveParameters();
    const isLoading =
        addTemplateParametersToConsumerMutationLoading || removeTemplateParameterLoading;
    const onRemoveTemplateParameter = useCallback(
        async (paramId: string) => {
            await removeTemplateParameter({
                variables: { guid: paramId, gameId: gameId! },
            });
        },
        [gameId, removeTemplateParameter],
    );
    const onAddDescriptionParams = useCallback(async () => {
        await addDescriptionParams({
            gameId: gameId!,
            templateId: templateId!,
            // variables: { gameId: gameId!, templateId: templateId! },
        });
        return onCompleted();
    }, [addDescriptionParams, gameId, onCompleted, templateId]);
    const onAddParameter = useCallback(async () => {
        await showTemplateParamModal({
            gameId: gameId!,
            templateId: templateId!,
        });
        onCompleted();
    }, [gameId, onCompleted, showTemplateParamModal, templateId]);
    const onUpdateParameter = useCallback(
        async (paramId: string) => {
            if (!template) throw new Error("Template is not defined");

            await showTemplateParamModal({
                gameId: gameId!,
                templateId: templateId!,
                templateParamId: paramId,
            });
            onCompleted();
        },
        [gameId, onCompleted, showTemplateParamModal, template, templateId],
    );
    const onAssignToConsumer = useCallback(
        async (consumerId: string | null) => {
            if (!consumerId || !gameId || !templateId)
                throw new Error(
                    "One or more of the following is missing: consumerId, gameId, templateId",
                );
            await addTemplateParametersToConsumerMutation({
                variables: {
                    consumerId,
                    gameId,
                    templateId,
                },
            });
            await getTemplate.refetch();
        },
        [addTemplateParametersToConsumerMutation, gameId, getTemplate, templateId],
    );

    return (
        <DataAwaiter2 {...getTemplate}>
            {(data) => (
                <>
                    <Helmet title="Parameters" />
                    <div className="gx-4 mb-4 align-items-center justify-content-start">
                        <ButtonGroup>
                            <Button variant="success" onClick={onAddParameter} disabled={isLoading}>
                                Add parameter
                            </Button>
                            {isDocumentType && !hasDescriptiveParameters && (
                                <Button
                                    variant="warning"
                                    onClick={onAddDescriptionParams}
                                    disabled={isLoading}
                                >
                                    Add descriptive parameters
                                </Button>
                            )}
                            <DropdownButton
                                as={ButtonGroup}
                                title="Add all parameters to consumer"
                                disabled={isLoading}
                                onSelect={onAssignToConsumer}
                            >
                                {availableConsumers.map((consumer) => (
                                    <Dropdown.Item key={consumer.guid} eventKey={consumer.guid}>
                                        {consumer.name}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </ButtonGroup>
                    </div>
                    <TemplateParamsTable
                        onRemoveTemplateParameter={onRemoveTemplateParameter}
                        onUpdateParameter={onUpdateParameter}
                        template={data.template}
                        loading={isLoading}
                    />
                </>
            )}
        </DataAwaiter2>
    );
}

const TemplateParametersRBAC = withRBAC(TemplateParameters, {
    requiredPermissions: [Permission.StructureWrite, Permission.StructureRead],
    oneOf: true,
});
export default TemplateParametersRBAC;
export const templateParametersRoute = createRoute({
    getParentRoute: () => templatePageRoute,
    path: "params",
    component: TemplateParametersRBAC,
});
