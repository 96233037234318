import { useCallback, useMemo } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { createColumnHelper } from "@tanstack/react-table";
import Stack from "react-bootstrap/Stack";
import { FaTrash } from "react-icons/fa";
import { useHasPermissions } from "@/components/rbac";
import { Permission, AssetFragment } from "@/gql";
import { useDeleteAssetMutation, useGetAssets } from "@/graphql/assets";
import { FileSizeDisplay } from "@/components/common/FileSizeDisplay";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { DataTable2 } from "@/components/common/DataTable2";
import { useEditAssetModal } from "./modals/EditAssetModal";

type Props = {
    gameId: string;
};

const columnHelper = createColumnHelper<AssetFragment>();

export function AssetsTable(props: Props) {
    const { gameId } = props;
    const [deleteAssetMutation] = useDeleteAssetMutation();
    const getAssets = useGetAssets({ gameId });
    const canEdit = useHasPermissions([Permission.DataWrite]);
    const openAssetModal = useEditAssetModal();
    const removeAsset = useCallback(
        async (guid: string) => {
            await deleteAssetMutation({ variables: { guid, gameId } });
            return getAssets.refetch();
        },
        [deleteAssetMutation, gameId, getAssets],
    );
    const addAsset = useCallback(async () => {
        await openAssetModal({ gameId });
        return getAssets.refetch();
    }, [gameId, getAssets, openAssetModal]);
    const columns = useMemo(
        () => [
            columnHelper.accessor("filename", { header: "Filename" }),
            columnHelper.accessor("name", { header: "Name" }),
            columnHelper.accessor("description", {
                header: "Description",
                cell: (props) => {
                    const value = props.getValue();
                    return value === "" ? (
                        <span className="fst-italic">No description</span>
                    ) : (
                        value
                    );
                },
            }),
            columnHelper.accessor("extension", {
                header: "Extension",
                maxSize: 100,
            }),
            columnHelper.accessor("mime", {
                header: "Mime",
            }),
            columnHelper.accessor("size", {
                header: "Size",
                maxSize: 100,
                cell: (props) => <FileSizeDisplay bytes={props.getValue()} />,
            }),
            columnHelper.accessor("hash", {
                header: "Hash",
                cell: (props) => <span className="font-monospace">{props.getValue()}</span>,
            }),
            columnHelper.accessor("guid", {
                header: "Actions",
                maxSize: 100,
                cell: (props) => {
                    const guid = props.getValue();

                    return (
                        <ButtonGroup size="sm">
                            <ConfirmationButton
                                prompt="Are you sure you want to delete this asset?"
                                variant="danger"
                                title="Delete"
                                onConfirm={() => removeAsset(guid)}
                            >
                                <FaTrash />
                            </ConfirmationButton>
                        </ButtonGroup>
                    );
                },
            }),
        ],
        [removeAsset],
    );

    return (
        <Stack gap={2}>
            <div>
                <ButtonGroup>
                    <Button onClick={addAsset} disabled={!canEdit}>
                        Upload new
                    </Button>
                </ButtonGroup>
            </div>
            <DataAwaiter2 {...getAssets}>
                {(data) => (
                    <DataTable2
                        data={data.result}
                        columns={columns}
                        tableOptions={{
                            enableSorting: false,
                            manualSorting: false,
                        }}
                    />
                )}
            </DataAwaiter2>
        </Stack>
    );
}
