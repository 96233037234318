import { useCallback, useMemo, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

const takeKey = "translations.itemsPerPage";
const skipKey = "translations.page";

export function usePagination(defaultItemsPerPage?: number) {
    const [take, setTake] = useLocalStorage<number>(takeKey, defaultItemsPerPage ?? 25);
    const [skip, setSkip] = useLocalStorage<number>(skipKey, 0);
    const [query, setQuery] = useState("");
    const onNextPage = useCallback(() => setSkip(skip + take), [setSkip, skip, take]);
    const onPrevPage = useCallback(() => setSkip(Math.max(skip - take, 0)), [setSkip, skip, take]);
    const onRowsPerPageChange = useCallback(
        (newItemsPerPage: number) => {
            const newPage = Math.floor((take * (skip - 1)) / newItemsPerPage);
            setSkip(newPage);
            setTake(newItemsPerPage);
        },
        [take, skip, setTake, setSkip],
    );

    return useMemo(
        () => ({
            query,
            skip,
            take,
            setQuery,
            onNextPage,
            onPrevPage,
            onRowsPerPageChange,
            setTake,
            setSkip,
        }),
        [onNextPage, onPrevPage, onRowsPerPageChange, query, setSkip, setTake, skip, take],
    );
}
