import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { CreateTemplateMutation, TemplateType } from "@/gql";
import { useCreateTemplateAction } from "@/store/games/actions";
import { TemplateMeta } from "@/types";
import { ProgressButton } from "../../ProgressButton";
import { FormInput } from "../../forms/form-inputs/FormInput";
import { FormSelect } from "../../forms/form-inputs/FormSelect";

export interface CreateTemplateFormData {
    name: string;
    description: string;
    meta: TemplateMeta;
    type: TemplateType;
}

type Props = {
    gameId: string;
    initialData?: CreateTemplateFormData;
};

const CreateTemplateModal = create(function CreateTemplateModal(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const [createTemplateAction] = useCreateTemplateAction();
    const createTemplate = useCallback<SubmitHandler<CreateTemplateFormData>>(
        async (data) => {
            const newTemplate = await createTemplateAction({
                ...data,
                gameId: props.gameId,
            });
            modal.resolve(newTemplate);
            return modal.hide();
        },
        [createTemplateAction, modal, props.gameId],
    );
    const form = useForm<CreateTemplateFormData>({
        defaultValues: {
            name: props.initialData?.name ?? "",
            description: props.initialData?.description ?? "",
            meta: props.initialData?.meta ?? {},
            type: props.initialData?.type ?? TemplateType.Document,
        },
        mode: "onChange",
    });
    const templateTypes = useMemo(
        () => Object.keys(TemplateType).map((type) => ({ value: type, label: type })),
        [],
    );

    return (
        <Modal
            backdrop={form.formState.isDirty ? "static" : undefined}
            keyboard={!form.formState.isDirty}
            {...dialog}
        >
            <Form onSubmit={form.handleSubmit(createTemplate, console.error)}>
                <FormProvider {...form}>
                    <Modal.Header closeButton={form.formState.isDirty}>
                        <Modal.Title>Create template</Modal.Title>
                    </Modal.Header>
                    <Modal.Body as={Stack} gap={2}>
                        <FormInput
                            className="mb-2"
                            name="name"
                            control={form.control}
                            title="Template name"
                            rules={{
                                required: { value: true, message: "This field is required" },
                                min: 3,
                                max: 250,
                                pattern: {
                                    value: /^[A-Z][\w\d]+$/,
                                    message: "Must be camelcase and not end with spaces",
                                },
                            }}
                        />
                        <FormInput
                            className="mb-2"
                            name="description"
                            as="textarea"
                            control={form.control}
                            title="Description"
                            rules={{ required: false, max: 1024 }}
                        />
                        <FormSelect
                            className="mb-2"
                            name="type"
                            control={form.control}
                            title="Template type"
                            options={templateTypes}
                            rules={{ required: true }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <ProgressButton
                            type="submit"
                            color="danger"
                            loading={form.formState.isSubmitting}
                            disabled={!form.formState.isValid}
                        >
                            Create template
                        </ProgressButton>
                    </Modal.Footer>
                </FormProvider>
            </Form>
        </Modal>
    );
});

export function useCreateTemplate() {
    return useCallback((props: Props) => {
        return show<CreateTemplateMutation["result"], NiceModalHocProps & Props, Props>(
            CreateTemplateModal,
            props,
        );
    }, []);
}
