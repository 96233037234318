import { createContext, ReactNode, useContext, useMemo } from "react";
import { QueryResult } from "@apollo/client/react/types/types";
import { TranslationsQuery, TranslationsQueryVariables } from "@/gql";
import { useGetTranslations } from "@/graphql/translation";
import { usePagination } from "@/components/translation/translations/hooks/usePagination";
import { DataAwaiter2 } from "../../common/DataAwaiter2";

type TranslationsPageContext = {
    translations: QueryResult<TranslationsQuery, TranslationsQueryVariables>;
    pagination: ReturnType<typeof usePagination>;
};

const TranslationsPageContext = createContext<TranslationsPageContext>(undefined!);

export function TranslationsPageContextProvider(props: {
    gameId: string;
    children: (data: TranslationsQuery) => ReactNode[] | ReactNode;
}) {
    const { gameId } = props;
    const pagination = usePagination(50);
    const getTranslations = useGetTranslations({ gameId, ...pagination });
    const contextValue = useMemo<TranslationsPageContext>(
        () => ({
            translations: getTranslations,
            pagination,
        }),
        [getTranslations, pagination],
    );

    return (
        <TranslationsPageContext.Provider value={contextValue}>
            <DataAwaiter2 {...getTranslations} keepPrev>
                {(data) => props.children(data)}
            </DataAwaiter2>
        </TranslationsPageContext.Provider>
    );
}

export function useTranslationsPageContext() {
    return useContext(TranslationsPageContext);
}
