import { createRoute } from "@tanstack/react-router";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { useDeployDirectoriesQuery } from "@/graphql/deploy-directories";
import { withRBAC } from "@/components/rbac";
import { Permission } from "@/gql";
import { DeployDirectoriesTable } from "@/components/deploy-directories/DeployDirectoriesTable";
import { projectSettings } from "./ProjectSettings";

const DeployDirectoriesPage = withRBAC(
    function DeployDirectoriesPage() {
        const { useParams } = deployDirectoriesRoute;
        const { gameId } = useParams();
        const deployDirectories = useDeployDirectoriesQuery({
            gameId: gameId!,
        });
        return (
            <DataAwaiter2 {...deployDirectories}>
                {(data) => (
                    <DeployDirectoriesTable
                        deployDirectories={data.deployDirectories}
                        gameId={gameId!}
                    />
                )}
            </DataAwaiter2>
        );
    },
    {
        requiredPermissions: [Permission.StructureWrite],
    },
);

export default DeployDirectoriesPage;

export const deployDirectoriesRoute = createRoute({
    getParentRoute: () => projectSettings,
    path: "deploy-directories",
    component: DeployDirectoriesPage,
});
