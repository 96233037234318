import { memo } from "react";
import styled from "styled-components";
import cn from "classnames";
import { TemplateCategories } from "@/components/layout/TemplateCategoriesSidebar";
import { useTemplateCategories } from "@/graphql/templates";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

const SidebarWrapper = styled.div`
    width: 70vw;
    top: 0;
    bottom: 0;
`;

type Props = {
    className?: string;
    onItemSelected: () => void;
};

export const SecondarySidebar = memo(function SecondarySidebar(props: Props) {
    const { useParams } = layoutWithBreadcrumbs;
    const { gameId } = useParams();
    const getTemplateCategories = useTemplateCategories({ gameId });

    return (
        <SidebarWrapper
            className={cn(
                "d-flex flex-column flex-shrink-0 p-3 bg-body-tertiary shadow z-3",
                props.className,
            )}
        >
            <DataAwaiter2 {...getTemplateCategories}>
                {(data) => (
                    <TemplateCategories
                        templateCategories={data.templateCategories}
                        onItemSelected={props.onItemSelected}
                    />
                )}
            </DataAwaiter2>
        </SidebarWrapper>
    );
});
