import Badge from "react-bootstrap/Badge";
import { CellContext } from "@tanstack/react-table";
import { Locale } from "@/types";

export function LocaleNameCell(props: CellContext<Locale, "guid">) {
    const row = props.row.original;
    return (
        <span>
            {row.name} {row.isBaseLocale && <Badge>Base locale</Badge>}
        </span>
    );
}
