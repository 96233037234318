import ReactSelect, { Props as SelectProps, StylesConfig } from "react-select";
import { useMemo } from "react";
import merge from "lodash/merge";

const classNames = {
    control: () => "form-control",
};

const styles: SelectProps["styles"] = {
    menuPortal: (base) => ({ ...base, zIndex: 2 }),
};

export function Select<Option, IsMulti extends boolean = false>(
    props: SelectProps<Option, IsMulti>,
) {
    const customStyles = useMemo<StylesConfig<Option, IsMulti>>(
        () => merge({}, props.styles, styles),
        [props.styles],
    );

    return (
        <ReactSelect
            {...props}
            styles={customStyles}
            menuPortalTarget={document.body}
            classNames={classNames}
        />
    );
}
