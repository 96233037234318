import { useCallback, useMemo, useState } from "react";
import Alert from "react-bootstrap/Alert";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useSnackbar } from "notistack";
import { createColumnHelper } from "@tanstack/react-table";
import { useGetTemplatesForImport, useImportEntitiesFrom } from "@/graphql/game";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { TemplateMatchModel } from "@/gql";
import { ConfirmationButton } from "@/components/common/ConfirmationButton";
import { useImportResultsModal } from "@/components/modals/ImportResultsModal";
import { DataTable2 } from "@/components/common/DataTable2";
import { ImportPageContext } from "@/components/import-page/ImportPageProvider";
import { ConditionalCellStyles } from "@/components/common/DataTable2/types";
import { ImportTemplateExpandedRow } from "./ImportTemplateExpandedRow";

interface Props {
    exportGameId: string;
    importGameId: string;
}

const columnHelper = createColumnHelper<TemplateMatchModel>();

export function ImportSummary(props: Props) {
    const getTemplatesForImport = useGetTemplatesForImport({
        sourceGameId: props.exportGameId,
        targetGameId: props.importGameId,
    });
    const showImportResultsModal = useImportResultsModal();
    const [importEntitiesFrom, { loading }] = useImportEntitiesFrom();
    const { enqueueSnackbar } = useSnackbar();
    const displayedData = useMemo(() => {
        if (!getTemplatesForImport.data) return [];

        return sortBy(getTemplatesForImport.data.getTemplatesForImport, (v) => v.sourceMatch.name);
    }, [getTemplatesForImport.data]);
    const templatesMap = useMemo(() => {
        return keyBy(getTemplatesForImport.data?.getTemplatesForImport, (v) => v.sourceMatch.guid);
    }, [getTemplatesForImport.data?.getTemplatesForImport]);
    const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
    const selectedTemplatesSet = useMemo(() => new Set(selectedTemplates), [selectedTemplates]);
    const isTemplateSelected = useCallback(
        (guid: string) => {
            return selectedTemplatesSet.has(guid);
        },
        [selectedTemplatesSet],
    );
    const canSelect = useCallback(
        (guid: string): boolean => {
            if (!getTemplatesForImport.data) return false;

            const match = getTemplatesForImport.data.getTemplatesForImport.find(
                (match) => match.sourceMatch.guid === guid,
            );

            return Boolean(match?.canBeImported);
        },
        [getTemplatesForImport.data],
    );
    const onTemplateSelect = useCallback((templateId: string) => {
        setSelectedTemplates((prevState) => {
            if (prevState.includes(templateId)) {
                return prevState.filter((v) => v !== templateId);
            } else {
                return [...prevState, templateId];
            }
        });
    }, []);
    const onDoImport = useCallback(async () => {
        const result = await importEntitiesFrom({
            variables: {
                targetGameId: props.importGameId,
                sourceGameId: props.exportGameId,
                templateIds: selectedTemplates,
            },
        });
        enqueueSnackbar("Import done", {
            variant: "success",
        });
        return showImportResultsModal({
            data: result.data!.importEntitiesFrom,
        });
    }, [
        importEntitiesFrom,
        props.importGameId,
        props.exportGameId,
        selectedTemplates,
        enqueueSnackbar,
        showImportResultsModal,
    ]);
    const importPageContextValue: ImportPageContext = {
        templatesMap,
        onSelect: (guid: string) => onTemplateSelect(guid),
        isTemplateSelected,
        canSelect,
    };
    const contextActions = useMemo(() => {
        const selectedCount = selectedTemplates.length;
        const text =
            selectedCount === 0
                ? "Import"
                : selectedCount === 1
                  ? `Import ${selectedCount} template`
                  : `Import ${selectedCount} templates`;

        return (
            <ConfirmationButton
                disabled={selectedCount === 0 || loading}
                variant="danger"
                onConfirm={onDoImport}
                prompt="Are you sure you want to import entities for selected templates? This action cannot be undone"
            >
                {loading && <Spinner size="sm" />}
                {text}
            </ConfirmationButton>
        );
    }, [loading, onDoImport, selectedTemplates.length]);
    const rowStyles = useMemo<ConditionalCellStyles<TemplateMatchModel>>(
        () => [
            {
                when: ({ row }) =>
                    !isTemplateSelected(row.original.sourceMatch.guid) && !row.original.targetMatch,
                classNames: ["bg-warning-subtle"],
            },
            {
                when: ({ row }) => !row.original.canBeImported,
                classNames: ["bg-danger-subtle"],
            },
            {
                when: ({ row }) => isTemplateSelected(row.original.sourceMatch.guid),
                classNames: ["bg-success-subtle"],
            },
        ],
        [isTemplateSelected],
    );
    const columns = useMemo(
        () => [
            columnHelper.accessor("sourceMatch.name", { header: "Template", size: 300 }),
            columnHelper.accessor("sourceMatch.guid", { header: "Template id", size: 400 }),
            columnHelper.accessor("sourceMatch.type", { header: "Template type", size: 200 }),
            columnHelper.accessor("targetMatch", {
                header: "Match id",
                cell: (props) => {
                    const targetMatch = props.getValue();
                    return targetMatch ? targetMatch.guid : "No match";
                },
            }),
            columnHelper.display({
                id: "actions",
                header: "",
                size: 150,
                cell: (props) => {
                    const row = props.row.original;

                    return (
                        <Button
                            disabled={!row.canBeImported}
                            variant={
                                selectedTemplatesSet.has(row.sourceMatch.guid)
                                    ? "outline-primary"
                                    : "primary"
                            }
                            size="sm"
                            onClick={() => onTemplateSelect(row.sourceMatch.guid)}
                        >
                            {!row.canBeImported
                                ? "Cannot import"
                                : selectedTemplatesSet.has(row.sourceMatch.guid)
                                  ? "Selected"
                                  : "Select"}
                        </Button>
                    );
                },
            }),
        ],
        [onTemplateSelect, selectedTemplatesSet],
    );

    if (getTemplatesForImport.data?.getTemplatesForImport.length === 0) {
        return (
            <Alert variant="danger">
                <Alert.Heading>No matching templates found</Alert.Heading>
            </Alert>
        );
    }

    return (
        <DataAwaiter2 {...getTemplatesForImport}>
            {() => (
                <ImportPageContext.Provider value={importPageContextValue}>
                    {contextActions}
                    <DataTable2
                        data={displayedData}
                        columns={columns}
                        bsTableProps={{ striped: true }}
                        conditionalCellStyles={rowStyles}
                        expandableRowsComponent={ImportTemplateExpandedRow}
                    />
                </ImportPageContext.Provider>
            )}
        </DataAwaiter2>
    );
}
