import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import { ImportedTemplateSummary } from "@/gql";

interface Props {
    data: ImportedTemplateSummary[];
}

const ImportResultsModal = create(function ImportResultsModal(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), []);

    return (
        <Modal {...dialog}>
            <Modal.Header>
                <Modal.Title>Import results</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {props.data.map((value) => (
                        <ListGroup.Item key={value.templateName} as="li">
                            <div className="fw-bold">{value.templateName}</div>
                            <div className="small ms-2">
                                # entities before: {value.entitiesBefore}
                            </div>
                            <div className="small ms-2">
                                # entities after: {value.entitiesAfter}
                            </div>
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
        </Modal>
    );
});

export function useImportResultsModal() {
    return useCallback((props: Props) => {
        return show<void, NiceModalHocProps & Props, Props>(ImportResultsModal, props);
    }, []);
}
