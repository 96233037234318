import { useCallback } from "react";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet-async";
import { useSnackbar } from "notistack";
import {
    closestCenter,
    DataRef,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { createRoute } from "@tanstack/react-router";
import {
    useAddTemplateToCategory,
    useCreateTemplateCategory,
    useTemplateCategories,
} from "@/graphql/templates";
import { DataAwaiter2 } from "@/components/common/DataAwaiter2";
import { Category } from "@/components/categories-page/Category";
import {
    AddTemplateToCategoryMutationVariables,
    Permission,
    Template,
    TemplateCategoriesQuery,
    TemplateCategory,
} from "@/gql";
import { useRenameCategoryModal } from "@/components/categories-page/RenameCategory";
import { withRBAC } from "@/components/rbac";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

type Props = {
    data: TemplateCategoriesQuery;
};

function Categories(props: Props) {
    const { useParams } = categoriesPageRoute;
    const { gameId } = useParams();
    const templateCategories = useTemplateCategories({ gameId: gameId! });
    const [addTemplateToCategory] = useAddTemplateToCategory();
    const { enqueueSnackbar } = useSnackbar();
    const [createTemplateCategory, { loading }] = useCreateTemplateCategory();
    const showRenameCategoryModal = useRenameCategoryModal();
    const onCreateCategory = useCallback(async () => {
        const result = await showRenameCategoryModal({});
        if (result !== null) {
            await createTemplateCategory({
                variables: {
                    gameId: gameId!,
                    name: result.categoryName,
                },
            });
            enqueueSnackbar("Category created", {
                variant: "success",
            });
            await templateCategories.refetch();
        }
    }, [createTemplateCategory, gameId, enqueueSnackbar, templateCategories]);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );
    const handleDragEnd = useCallback(
        async (event: DragEndEvent) => {
            const { active, over } = event;
            const templateData = active.data as DataRef<Template>;
            const templateId = templateData.current?.guid;
            const categoryData = over?.data as DataRef<TemplateCategory> | undefined;

            if (!categoryData || !templateId) return;

            const canDrop = !categoryData.current?.templates.find(
                (template) => template.guid === templateId,
            );

            if (!canDrop) return;

            const variables: AddTemplateToCategoryMutationVariables = {
                gameId: templateData.current!.gameId,
                templateId: templateData.current!.guid,
                categoryId: categoryData.current!.guid,
            };

            await addTemplateToCategory({ variables });
            await templateCategories.refetch();
            enqueueSnackbar("Template added to category", {
                variant: "success",
            });
        },
        [addTemplateToCategory, enqueueSnackbar, templateCategories],
    );

    return (
        <section>
            <Helmet title="Categories" />
            <div className="mb-4">
                <Button disabled={loading} onClick={onCreateCategory}>
                    Create category
                </Button>
            </div>
            <div className="d-flex flex-wrap">
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    {props.data.templateCategories.map((category) => (
                        <Category className="mb-5 w-25" key={category.guid} data={category} />
                    ))}
                </DndContext>
            </div>
        </section>
    );
}

const CategoriesPage = withRBAC(
    function CategoriesPage() {
        const { useParams } = categoriesPageRoute;
        const { gameId } = useParams();
        const templateCategories = useTemplateCategories({ gameId: gameId! });

        return (
            <DataAwaiter2 {...templateCategories}>
                {(data) => <Categories data={data} />}
            </DataAwaiter2>
        );
    },
    {
        requiredPermissions: [Permission.StructureRead, Permission.StructureWrite],
    },
);

export default CategoriesPage;
export const categoriesPageRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "categories",
    component: CategoriesPage,
});
