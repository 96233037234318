import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { Upload } from "@/gql";
import { useCreateUploadMutation } from "@/graphql/assets";
import { AssetRawData } from "../types";

export function useUploadAsset() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [createUploadMutation] = useCreateUploadMutation();

    const uploadAsset = useCallback(
        async (data: AssetRawData) => {
            let snackbarKey = enqueueSnackbar("Upload started", {
                variant: "info",
            });

            try {
                const result = await createUploadMutation({
                    variables: {
                        gameId: data.gameId,
                        name: data.name,
                        description: data.description,
                    },
                });
                const upload = result.data?.uploadAssetV2;

                if (!upload) {
                    closeSnackbar(snackbarKey);
                    enqueueSnackbar("Upload failed", {
                        variant: "error",
                        autoHideDuration: 3000,
                    });
                    throw new Error(result.errors?.[0]?.message ?? "Unknown upload error");
                }

                closeSnackbar(snackbarKey);
                snackbarKey = enqueueSnackbar("Upload created", { variant: "info" });

                const formData = new FormData();
                formData.append("file", data.file![0]!);

                const response = await fetch(
                    `${window.env.httpApiUrl}/assets/upload/${upload.guid}`,
                    {
                        method: "POST",
                        body: formData,
                    },
                );

                closeSnackbar(snackbarKey);
                snackbarKey = enqueueSnackbar("Upload finished", { variant: "success" });

                if (!response.ok) {
                    throw new Error(response.statusText);
                }

                return (await response.json()) as Upload;
            } catch (e) {
                enqueueSnackbar("Upload failed", {
                    variant: "error",
                    autoHideDuration: 3000,
                });
            }
        },
        [enqueueSnackbar, createUploadMutation, closeSnackbar],
    );

    return uploadAsset;
}
