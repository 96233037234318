import { createRoute } from "@tanstack/react-router";
import { TranslationsPageContextProvider } from "@/components/translation/translations/TranslationsPageProvider";
import { TranslationsTable } from "@/components/translation/translations/TranslationsTable";
import { layoutWithBreadcrumbs } from "@/layouts/LayoutWithBreadcrumbs";

export default function TranslationsPage() {
    const { useParams } = translationsPageRoute;
    const { gameId } = useParams();

    return (
        <TranslationsPageContextProvider gameId={gameId}>
            {(data) => (
                <TranslationsTable gameId={gameId} data={data} total={data.translationsTotal} />
            )}
        </TranslationsPageContextProvider>
    );
}

export const translationsPageRoute = createRoute({
    getParentRoute: () => layoutWithBreadcrumbs,
    path: "translation/table",
    component: TranslationsPage,
});
