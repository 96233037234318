import { useMemo } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import orderBy from "lodash/orderBy";
import { createColumnHelper } from "@tanstack/react-table";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Template, TemplateParam } from "@/gql";
import { CasedButton } from "@/components/common/CasedButton";
import { DatatypeRenderer } from "@/components/common/DatatypeRenderer";
import { DataTable2 } from "@/components/common/DataTable2";
import { stringify } from "@/utils/stringify";
import { ConfirmationButton } from "../../ConfirmationButton";
import { TemplateParameterConsumers } from "@/components/common/Table/TemplateParamsTable/TemplateParameterConsumers";

type TemplateParamsTableProps = {
    loading: boolean;
    template: Template;
    onUpdateParameter: (guid: string) => void;
    onRemoveTemplateParameter: (guid: string) => void;
};

const helper = createColumnHelper<Omit<TemplateParam, "template" | "creator">>();

export function TemplateParamsTable(props: TemplateParamsTableProps) {
    const templateParameters = useMemo(
        () => orderBy(props.template.templateParams ?? [], "name"),
        [props.template.templateParams],
    );

    const columns = useMemo(
        () => [
            helper.display({
                id: "templateId",
                header: "Parameter name",
                enableSorting: false,
                cell: (rowProps) => {
                    const { templateId, name, guid } = rowProps.row.original;

                    return (
                        <CasedButton
                            disabled={templateId !== props.template.guid}
                            className="p-0"
                            variant="link"
                            onClick={() => props.onUpdateParameter(guid)}
                        >
                            {name}
                        </CasedButton>
                    );
                },
            }),
            helper.accessor("description", {
                id: "description",
                header: "Description",
                maxSize: 200,
                minSize: 200,
                cell: (props) => (
                    <span className="small" title={props.getValue()}>
                        {props.getValue()}
                    </span>
                ),
            }),
            helper.display({
                id: "type",
                header: "Type",
                enableSorting: false,
                maxSize: 200,
                minSize: 200,
                cell: (props) => {
                    const { type, subType, meta, gameId } = props.row.original;

                    return (
                        <DatatypeRenderer
                            className="small"
                            type={type}
                            subType={subType}
                            templateId={meta.templateId}
                            gameId={gameId}
                        />
                    );
                },
            }),
            helper.accessor("meta.defaultValue", {
                id: "defaultValue",
                header: "Default value",
                cell: (props) => (
                    <div className="small">{stringify(props.getValue()) ?? "Null"}</div>
                ),
            }),
            helper.accessor("meta.isRequired", {
                id: "required",
                header: "Required",
                maxSize: 120,
                cell: (props) => <Form.Check defaultChecked={props.getValue()} />,
            }),
            helper.accessor("meta.isUnique", {
                id: "unique",
                header: "Unique",
                maxSize: 120,
                cell: (props) => <Form.Check defaultChecked={props.getValue()} />,
            }),
            helper.accessor("consumers", {
                id: "consumers",
                header: "Consumers",
                cell: TemplateParameterConsumers,
                // cell: (props) => {
                //     const consumers = props.getValue();
                //
                //     if (!consumers) return <span>No consumers assigned</span>;
                //
                //     return (
                //         <Stack>
                //             {consumers.map((c) => (
                //                 <div key={c.guid} className="small">
                //                     {c.name}
                //                 </div>
                //             ))}
                //         </Stack>
                //     );
                // },
            }),
            helper.display({
                id: "actions",
                header: "Actions",
                maxSize: 150,
                cell: (rowProps) => {
                    const { templateId, guid, name, meta } = rowProps.row.original;
                    return templateId === props.template.guid ? (
                        <ButtonGroup size="sm">
                            <Button
                                onClick={() => props.onUpdateParameter(guid)}
                                variant="warning"
                                disabled={props.loading}
                            >
                                Edit
                            </Button>
                            <ConfirmationButton
                                variant="danger"
                                prompt={`Are you sure you want to delete parameter ${name}`}
                                onConfirm={() => props.onRemoveTemplateParameter(guid)}
                                confirmationPhrase={name}
                                disabled={props.loading || meta.isProtected}
                            >
                                Remove
                            </ConfirmationButton>
                        </ButtonGroup>
                    ) : null;
                },
            }),
        ],
        [props],
    );

    return (
        <DataTable2
            columns={columns}
            data={templateParameters}
            tableOptions={{
                enableColumnResizing: false,
                enableSorting: false,
                manualSorting: false,
            }}
        />
    );
}
