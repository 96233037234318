import { useCallback, useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "notistack";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import { FormInput } from "@/components/common/forms/form-inputs/FormInput";
import { AuthResult, WizCreateUserMutationVariables } from "@/gql";
import { useProtectDirtyForm } from "@/hooks/useProtectDirtyForm";
import { ProgressButton } from "@/components/common/ProgressButton";
import { useCreateUserMutation } from "@/graphql/wizardry/users";

const CreateUserModal = create(function CreateUserModal() {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);
    const { enqueueSnackbar } = useSnackbar();
    const [createUser] = useCreateUserMutation();
    const form = useForm<WizCreateUserMutationVariables>({
        defaultValues: {
            password: "",
            email: "",
            firstname: "",
            lastname: "",
        },
        mode: "onChange",
        reValidateMode: "onChange",
    });
    const modalProtectionProps = useProtectDirtyForm(form);
    const onSubmit = useCallback<SubmitHandler<WizCreateUserMutationVariables>>(
        async (data) => {
            const result = await createUser({ variables: data });
            if (result.data) {
                enqueueSnackbar("Success", { variant: "success" });
                modal.resolve(result.data.wizCreateUser);
                return modal.hide();
            } else {
                console.dir(result);
                enqueueSnackbar("Fail", { variant: "error" });
            }
        },
        [createUser, enqueueSnackbar, modal],
    );

    return (
        <Modal {...dialog} {...modalProtectionProps}>
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <Modal.Header>
                    <Modal.Title>Create user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="gy-2" md={12}>
                        <Col md={6}>
                            <FormInput
                                className="mb-3"
                                control={form.control}
                                title="First Name"
                                name="firstname"
                                type="text"
                                rules={{ maxLength: 240, required: true }}
                            />
                        </Col>
                        <Col md={6}>
                            <FormInput
                                className="mb-3"
                                control={form.control}
                                title="Last Name"
                                name="lastname"
                                type="text"
                                rules={{ maxLength: 240, required: true }}
                            />
                        </Col>
                        <Col md={6}>
                            <FormInput
                                className="mb-3"
                                control={form.control}
                                title="Email"
                                name="email"
                                type="email"
                                rules={{ maxLength: 240, required: true }}
                            />
                        </Col>
                        <Col md={6}>
                            <FormInput
                                className="mb-3"
                                control={form.control}
                                title="Password"
                                name="password"
                                type="text"
                                rules={{ maxLength: 240, required: true }}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ProgressButton
                        type="submit"
                        color="danger"
                        disabled={!form.formState.isValid}
                        loading={form.formState.isSubmitting}
                    >
                        Create user
                    </ProgressButton>
                </Modal.Footer>
            </Form>
        </Modal>
    );
});

export function useWizCreateUser() {
    return useCallback(() => show<AuthResult, NiceModalHocProps, {}>(CreateUserModal, {}), []);
}
