import { useCallback, useMemo } from "react";
import { bootstrapDialog, create, NiceModalHocProps, show, useModal } from "@ebay/nice-modal-react";
import Modal from "react-bootstrap/Modal";
import { User } from "../types";
import { UserInfoExpanded } from "../UserInfoExpanded";

type Props = {
    data: User;
};

const EditUserPermissionsModal = create(function EditUserPermissionsModal(props: Props) {
    const modal = useModal();
    const dialog = useMemo(() => bootstrapDialog(modal), [modal]);

    return (
        <Modal {...dialog} size="xl">
            <Modal.Header>
                <Modal.Title>Create user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <UserInfoExpanded data={props.data} />
            </Modal.Body>
        </Modal>
    );
});

export function useEditUserPermissionsModal() {
    return useCallback(
        (props: Props) =>
            show<void, NiceModalHocProps & Props, Props>(EditUserPermissionsModal, props),
        [],
    );
}
