import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { useNavigate, createRoute } from "@tanstack/react-router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classNames from "classnames";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { SingleValue } from "react-select";
import { object } from "zod";
import ListGroup from "react-bootstrap/ListGroup";
import Stack from "react-bootstrap/Stack";
import isEmpty from "lodash/isEmpty";
import { withRBAC } from "@/components/rbac";
import { ConsumerArtifact, Permission } from "@/gql";
import { DataAwaiter } from "@/components/common/DataAwaiter";
import { ArtifactSelector } from "@/components/deploy/ArtifactsDiff/ArtifactSelector";
import { useArtifactDiff } from "@/components/deploy/ArtifactsDiff/hooks/useArtifactDiff";
import { Select } from "@/components/common/Select";
import { useGetArtifacts } from "@/graphql/deploy";
import { artifactRoute } from "@/pages/Artifacts";
import { Diff } from "@/components/common/Diff";
import { uuidValidator } from "@/utils/validators/uuid";

function ArtifactsDiffPage() {
    const { useParams } = artifactsDiffPage;
    const { gameId } = useParams();
    const navigate = useNavigate({ from: artifactsDiffPage.fullPath });
    const searchParams = artifactsDiffPage.useSearch();
    const { data: artifacts } = useGetArtifacts({
        gameId: gameId!,
    });
    const {
        data,
        loading,
        error,
        consumerArtifacts,
        diffSummary,
        selectedDiffEntry,
        setSelectedDiffEntry,
        setSelectedConsumerArtifactId,
    } = useArtifactDiff({
        gameId: gameId!,
        idA: searchParams.a,
        idB: searchParams.b,
    });
    const [filter, setFilter] = useState("");
    const onFilterChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        setFilter(event.target.value.toLowerCase());
    }, []);
    const filteredDiffSummary = useMemo(() => {
        if (isEmpty(filter)) return diffSummary;
        return diffSummary.filter((c) => c.text.includes(filter));
    }, [diffSummary, filter]);

    const onArtifactChange = useCallback(
        (option: "a" | "b") => {
            return async (guid: string) => {
                await navigate({
                    search: (prev) => ({ ...prev, [option]: guid }),
                });
            };
        },
        [navigate],
    );
    const onChangeArtifact = useCallback(
        (newValue: SingleValue<ConsumerArtifact>) => {
            if (newValue) {
                setSelectedConsumerArtifactId(newValue.consumerId);
            }
        },
        [setSelectedConsumerArtifactId],
    );

    if (!gameId) throw new Error("gameId expected");

    return (
        <DataAwaiter data={data} isLoading={loading} error={error}>
            {() => (
                <>
                    <Row>
                        <Col md={3} className="offset-3">
                            <label>Artifact A:</label>
                            {!artifacts ? (
                                <Spinner />
                            ) : (
                                <ArtifactSelector
                                    gameId={gameId}
                                    value={searchParams.a}
                                    options={artifacts?.artifacts}
                                    onChange={onArtifactChange("a")}
                                />
                            )}
                        </Col>
                        <Col md={3}>
                            <label>Consumer:</label>
                            <Select<ConsumerArtifact, false>
                                options={consumerArtifacts}
                                getOptionLabel={(v) => v.consumerName}
                                getOptionValue={(v) => v.consumerId}
                                onChange={onChangeArtifact}
                            />
                        </Col>
                        <Col md={3}>
                            <label>Artifact B:</label>
                            {!artifacts ? (
                                <Spinner />
                            ) : (
                                <ArtifactSelector
                                    gameId={gameId}
                                    value={searchParams.b}
                                    options={artifacts?.artifacts}
                                    onChange={onArtifactChange("b")}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="overflow-hidden flex-grow-1">
                        <Col xl={3} className="overflow-y-scroll h-100">
                            <Stack gap={1}>
                                <Form.Control
                                    placeholder="Filter..."
                                    value={filter}
                                    onChange={onFilterChange}
                                />
                                <ListGroup className="flex-column" variant="pills">
                                    {filteredDiffSummary.map((v) => (
                                        <ListGroup.Item
                                            active={v === selectedDiffEntry}
                                            action
                                            onClick={() => setSelectedDiffEntry(v)}
                                            key={v.text}
                                            className={classNames("p-1", {
                                                "text-danger": v.status === "removed",
                                                "text-success": v.status === "added",
                                                "text-warning": v.status === "changed",
                                            })}
                                        >
                                            {v.text}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Stack>
                        </Col>
                        <Col md={9}>
                            {selectedDiffEntry && (
                                <Diff
                                    from={selectedDiffEntry.dataFrom}
                                    to={selectedDiffEntry.dataTo}
                                />
                            )}
                        </Col>
                    </Row>
                </>
            )}
        </DataAwaiter>
    );
}

const validateSearch = object({
    a: uuidValidator().optional(),
    b: uuidValidator().optional(),
});

const ArtifactsDiffPageRBAC = withRBAC(ArtifactsDiffPage, {
    requiredPermissions: [Permission.StructureRead, Permission.CanDeploy],
});
export default ArtifactsDiffPageRBAC;
export const artifactsDiffPage = createRoute({
    getParentRoute: () => artifactRoute,
    path: "diff",
    component: ArtifactsDiffPageRBAC,
    validateSearch,
});
