import { useMemo } from "react";
import sortBy from "lodash/sortBy";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import { createColumnHelper } from "@tanstack/react-table";
import { DeployDirectory } from "@/gql";
import { stringify } from "@/utils/stringify";
import {
    useLazyGetArtifactEntitiesFile,
    useLazyGetArtifactMetadataFile,
    useLazyGetArtifactVersionsFile,
} from "@/graphql/deploy";
import { ArtifactActionsCell } from "@/components/deploy/ArtifactActionsCell";
import { DataTable2 } from "@/components/common/DataTable2";
import { ConsumerArtifact, ConsumerArtifactData } from "./types";
import { resolveBucketPath } from "./utils";

type Props = {
    deployedOn: DeployDirectory[];
    consumerArtifact: ConsumerArtifact;
};

const columnHelper = createColumnHelper<ConsumerArtifactData>();

export function ArtifactDetails(props: Props) {
    const { deployedOn } = props;
    const [getArtifactEntitiesFile] = useLazyGetArtifactEntitiesFile();
    const [getArtifactMetadataFile] = useLazyGetArtifactMetadataFile();
    const [getArtifactVersionsFile] = useLazyGetArtifactVersionsFile();
    const { gameId, guid } = props.consumerArtifact;

    const bucketFilesMap = useMemo(
        () =>
            mapValues(
                keyBy(props.consumerArtifact?.dataFiles, (v) => v.id),
                (v) => `data/${v.name}_v${v.version}.json`,
            ),
        [props.consumerArtifact?.dataFiles],
    );
    const bucketPathsMap = useMemo(
        () =>
            mapValues(
                keyBy(props.consumerArtifact?.dataFiles, (v) => v.id),
                // (v) => `data/${v.name}_v${v.version}.json`,
                (v) =>
                    resolveBucketPath({
                        gameId: props.consumerArtifact.gameId,
                        consumerId: props.consumerArtifact.consumerId,
                        deployDirectoryPath: "%DD%",
                        entityName: v.name,
                        entityVersion: v.version,
                    }),
            ),
        [
            props.consumerArtifact.consumerId,
            props.consumerArtifact?.dataFiles,
            props.consumerArtifact.gameId,
        ],
    );
    const versionsFileData = useMemo(
        () => ({
            name: "versions.json",
            data: async () => {
                const data = await getArtifactVersionsFile({ variables: { gameId, guid } });
                return stringify(data.data?.artifactVersionsFile);
            },
        }),
        [gameId, getArtifactVersionsFile, guid],
    );
    const configMetaData = useMemo(
        () => ({
            name: "config_meta.json",
            data: async () => {
                const data = await getArtifactMetadataFile({ variables: { gameId, guid } });
                return stringify(data.data?.artifactMetadataFile);
            },
        }),
        [gameId, getArtifactMetadataFile, guid],
    );
    const dataFilesData = useMemo(
        () =>
            sortBy(
                (props.consumerArtifact.dataFiles ?? [])
                    .filter((v) => Boolean(bucketPathsMap[v.id]))
                    .map(
                        (dataFile): ConsumerArtifactData => ({
                            name: bucketFilesMap[dataFile.id]!,
                            data: async () => {
                                const data = await getArtifactEntitiesFile({
                                    variables: { gameId, guid, name: dataFile.name },
                                });
                                return stringify(
                                    data.data?.artifactEntitiesFile,
                                    "__typename",
                                    "bucketPath",
                                );
                            },
                            bucketPath: bucketPathsMap[dataFile.id],
                        }),
                    ),
                ["name"],
            ),
        [
            bucketFilesMap,
            bucketPathsMap,
            gameId,
            getArtifactEntitiesFile,
            guid,
            props.consumerArtifact.dataFiles,
        ],
    );
    const data = useMemo<ConsumerArtifactData[]>(
        () => [versionsFileData, configMetaData, ...dataFilesData],
        [configMetaData, dataFilesData, versionsFileData],
    );
    const columns = useMemo(
        () => [
            columnHelper.display({
                header: "Actions",
                cell: (props) => <ArtifactActionsCell {...props} deployedOn={deployedOn} />,
            }),
            columnHelper.accessor("name", {
                header: "Entry",
                cell: (props) => props.getValue(),
            }),
        ],
        [deployedOn],
    );

    return <DataTable2 data={data} columns={columns} />;
}
