import { useMemo } from "react";
import { UseFormReturn } from "react-hook-form";

export function useProtectDirtyForm(form: UseFormReturn<any>) {
    return useMemo(
        () => ({
            backdrop: form.formState.isDirty ? ("static" as const) : undefined,
            keyboard: !form.formState.isDirty,
        }),
        [form.formState.isDirty],
    );
}
